import React, { useCallback } from "react";
import {
  Link,
  useNavigate,
  useSearchParams,
  generatePath,
  useParams,
} from "react-router-dom";
import StepperProgressBar from "../../../components/StepperProgressBar";
import Button from "../../Button";
import useGoBack from "../../../hooks/useGoBack";
import { useCreateListingContext } from "contexts/create-listing";
import { ALL_BIKE_STEPS } from "containers/Listings/CreateListing/ListingSteps/Bikes/steps";
import { LISTING_ROUTES } from "contexts/create-listing/routes";
import { appConfig } from "app-config";

const ListingFooter = ({
  backUrl,
  nextUrl,
  onNext,
  disableBack,
  disableNext,
}) => {
  const { currentStep, setCurrentStep, paths } = useCreateListingContext();
  const params = useParams();
  const [searhParams] = useSearchParams();
  const mode = searhParams.get("mode");

  const isReviewMode = mode === "review";

  const goback = useGoBack();
  const navigate = useNavigate();

  const onPrevStep = useCallback((isLink) => {
    setCurrentStep((p) => (p > 1 ? p - 1 : p));
    if (!isLink) {
      goback();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onNextStep = useCallback((isLink) => {
    setCurrentStep((p) => (p < paths.length ? p + 1 : p));
    if (!isLink) {
      onNext();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const jumpToReviewButton = (
    <Button
      disabled={disableNext}
      onClick={() => {
        setCurrentStep(ALL_BIKE_STEPS.Review.step);
        const path = generatePath(ALL_BIKE_STEPS.Review.path, {
          listingId: params.listingId,
          typeId: params.typeId,
        });
        navigate(path);
      }}
    >
      Next & Save
    </Button>
  );

  return (
    <footer className="bg-white shadow-sm border border-t-2 fixed bottom-0 w-full h-[72px]">
      {/* <ProgressLine height={1} width="50%" /> */}
      <StepperProgressBar
        completedSteps={currentStep}
        totalSteps={paths.length}
      />
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex justify-between items-center lg:gap-8 py-4">
          <div className="flex md:inset-y-0 md:left-0 lg:static xl:col-span-2">
            <div className="flex flex-shrink-0 items-center">
              {backUrl ? (
                <Link to={backUrl} onClick={() => onPrevStep(true)}>
                  Back
                </Link>
              ) : (
                <a onClick={() => onPrevStep(false)}>Back</a>
              )}
            </div>
          </div>

          {appConfig.showListingStepCount && (
            <p className="text-sm font-bold">
              Step {currentStep} of {LISTING_ROUTES.length}
            </p>
          )}

          {nextUrl ? (
            <>
              {isReviewMode ? (
                jumpToReviewButton
              ) : (
                <Link to={nextUrl} onClick={() => onNextStep(true)}>
                  <Button disabled={disableNext}>Next</Button>
                </Link>
              )}
            </>
          ) : (
            <>
              {isReviewMode ? (
                jumpToReviewButton
              ) : (
                <Button disabled={disableNext} onClick={onNextStep}>
                  Next
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </footer>
  );
};

export default React.memo(ListingFooter);
