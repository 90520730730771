import React, { useState } from "react";
import cn from "lib/cn";
import { Controller, useFormContext } from "react-hook-form";

import Textarea from "components/FormControls/Textarea";

const FormTextarea = ({
  label,
  name,
  placeholder,
  defaultValue,
  autoFocus,
  rows = 1,
  maxRows = 1,
  resizable = false,
  required = false,
  maxLength,
}) => {
  const { control } = useFormContext();

  const inputId = React.useMemo(() => `input-${name}`, [name]);

  return (
    <>
      {label && (
        <label
          htmlFor={inputId}
          className={cn("block text-sm font-medium leading-6 text-gray-900", {
            asterisk: required,
          })}
        >
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field, fieldState: { error } }) => {
          return (
            <>
              <div className="relative">
                <Textarea
                  {...field}
                  rows={rows}
                  maxRows={maxRows}
                  name={name}
                  id={inputId}
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  maxLength={maxLength}
                  resizable={resizable}
                  error={error}
                />
              </div>
            </>
          );
        }}
      />
    </>
  );
};

export default FormTextarea;
