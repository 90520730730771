import React from "react";
import FileType from "components/FileType";
import { ReactComponent as TrashIcon } from "icons/trash.svg";
import Progress from "./Progress";
import IconButton from "components/IconButton";
import { getFileType, getHumanReadableFileSize } from "utils/file-functions";

const UploadedFile = React.memo(({ type = "n/a", name, size, onRemove }) => {
  const fileType = getFileType(type);
  const humanReadableSize = getHumanReadableFileSize(size);

  return (
    <div className="border border-solid border-gray-100 rounded-lg">
      <div className="flex items-center p-4 gap-x-3">
        <div className="self-start">
          <FileType extension={fileType} />
        </div>
        <div className="flex flex-col flex-1 gap-x-3">
          <div className="flex justify-between items-center gap-x-3">
            <div className="flex-1">
              <p>{name || "--"}</p>
              <p>{humanReadableSize || "--"}</p>
            </div>
            <IconButton tooltip="Remove" onClick={onRemove}>
              <TrashIcon className="[&>path]:stroke-red-600 focus:outline-none" />
            </IconButton>
          </div>
          <div className="flex items-center justify-between gap-x-3">
            {/* <div className="h-2 rounded-md flex-1 bg-primary" /> */}
            <Progress percentage={50} />
            <p>100%</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default UploadedFile;
