import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router";

function PrivateLayout() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  if (!user) {
    return <Navigate to="/login" replace state={{ from: location.pathname }} />;
  }
  return <Outlet />;
}

export default PrivateLayout;
