import { Controller, useFormContext } from "react-hook-form";
import Dropzone from "react-dropzone";
import FileUploader from "components/FileUploader";

function File({ name, accept }) {
  const { control, getValues, setValue } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur } }) => (
        <Dropzone
          noClick
          accept={accept}
          onDrop={(acceptedFiles) => {
            const existingPhotos = getValues(name);
            setValue(name, acceptedFiles.concat(existingPhotos), {
              shouldValidate: true,
            });
          }}
        >
          {({ getRootProps, getInputProps, isDragActive, open }) => (
            <>
              <div className="w-full" {...getRootProps()}>
                <input
                  {...getInputProps({
                    onChange,
                    onBlur,
                  })}
                />
                {isDragActive ? (
                  <FileUploader active onOpen={open} />
                ) : (
                  <FileUploader onOpen={open} />
                )}
              </div>
            </>
          )}
        </Dropzone>
      )}
    />
  );
}

export default File;
