import React from "react";
import ReactSelect from "react-select";

const Select = ({
  className,
  defaultValue,
  disabled,
  loading,
  isClearable,
  isSearchable,
  name,
  options = [],
  value,
  onChange,
  placeholder,
  menuPlacement = "bottom", // auto, bottom, top,
  openMenuOnFocus = false,
  autoFocus = false,
}) => {
  return (
    <ReactSelect
      openMenuOnFocus={openMenuOnFocus}
      autoFocus={autoFocus}
      options={options}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className}
      defaultValue={defaultValue}
      isDisabled={disabled}
      isLoading={loading}
      isClearable={isClearable}
      isSearchable={isSearchable}
      name={name}
      menuPlacement={menuPlacement}
    />
  );
};

export default Select;
