import React from "react";
import cn from "classnames";

const StepperProgressBar = ({ totalSteps = 7, completedSteps = 1 }) => {
  return (
    <div className="flex gap-x-1">
      {[...new Array(totalSteps)].map((_, index) => (
        <div
          key={`stepper__${index}`}
          className={cn("h-[2px] w-full", {
            "bg-primary": index + 1 <= completedSteps,
            "bg-gray-200": index + 1 > completedSteps,
          })}
        />
      ))}
    </div>
  );
};

export default StepperProgressBar;
