export const SeoConfig = {
  "/": {
    title: "Home",
  },
  "/login": {
    title: "Login",
  },
  "/register": {
    title: "Register",
  },
  "/become-a-seller/:listingId/select-listing": {
    title: "Select Listing",
  },
  "/become-a-seller/:listingId/select-listing/:typeId": {
    title: "Select Listing Type",
  },
  "/become-a-seller/:listingId/:typeId/equipment-details": {
    title: "Equipment Details",
  },
  "/become-a-seller/:listingId/:typeId/equipment-features": {
    title: "Equipment Features",
  },
  "/become-a-seller/:listingId/:typeId/rental-details": {
    title: "Rental Details",
  },
  "/become-a-seller/:listingId/:typeId/price": {
    title: "Equipment Price",
  },
  "/become-a-seller/:listingId/:typeId/discount": {
    title: "Equipment Discount",
  },
  "/become-a-seller/:listingId/:typeId/availability": {
    title: "Equipment Availability",
  },
  "/become-a-seller/:listingId/:typeId/review": {
    title: "Equipment Review",
  },
};
