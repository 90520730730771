import React from "react";
import cn from "lib/cn";
import { ReactComponent as ExclamationCircleIcon } from "icons/exclamation-circle.svg";
import { ReactComponent as ValidIcon } from "icons/input-check.svg";
import HintText from "components/HintText";

const Input = (
  {
    className,
    error,
    errorClassName = "",
    hint,
    valid,
    iconRight,
    count,
    maxLength,
    ...rest
  },
  ref
) => {
  let rightIcon;

  if (error) {
    rightIcon = (
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
        <ExclamationCircleIcon
          className="h-5 w-5 text-red-500"
          aria-hidden="true"
        />
      </div>
    );
  } else if (iconRight) {
    rightIcon = (
      <div className="absolute inset-y-0 right-0 flex items-center pr-3">
        {iconRight}
      </div>
    );
  } else if (valid) {
    rightIcon = (
      <div className="absolute right-0 top-0 h-full flex justify-center items-center pr-[14px]">
        <ValidIcon />
      </div>
    );
  }

  return (
    <>
      <div className="relative">
        <input
          {...rest}
          className={cn(
            "block w-full rounded-md py-[10px] px-[14px] font-normal text-base border-0 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset ring-gray-300 placeholder:text-[#667085] focus:ring-primary sm:text-sm sm:leading-6",
            className,
            {
              "text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500":
                error,
              errorClassName: error,
            }
          )}
          ref={ref}
        />
        {rightIcon}
      </div>
      {error && <p className="text-xs text-red-500 mt-1">{error.message}</p>}
      <div className="flex justify-between items-center">
        <HintText className="mt-1 flex-1">{hint}</HintText>
        {maxLength && (
          <p className="text-xs text-right mt-1">
            {count} / {maxLength}
          </p>
        )}
      </div>
    </>
  );
};

export default React.forwardRef(Input);
