import React from "react";
import cn from "classnames";
import styles from "./Spinner.module.sass";

const Spinner = (props) => {
  return (
    <span
      className={cn(styles.loader, props.className || "")}
      style={props.style || {}}
    ></span>
  );
};

export default Spinner;
