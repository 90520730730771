import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ButtonGroup as GroupButton } from "components/Button";

const ButtonGroup = ({ items = [], name, defaultValue }) => {
  const { control, setValue } = useFormContext();
  const onChange = (selected) => {
    setValue(name, selected);
  };

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      render={({ field }) => {
        return (
          <GroupButton
            items={items}
            defaultValue={defaultValue}
            value={field.value}
            onChange={onChange}
          />
        );
      }}
    />
  );
};

export default ButtonGroup;
