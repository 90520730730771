import React, { memo } from "react";
import cn from "lib/cn";
import ListingHeader from "../ListingHeader";

const ListingPage = ({
  header,
  footer,
  breadcrumbs,
  containerClass = "",
  children,
}) => {
  return (
    <div className="flex flex-1 flex-col bg-white overflow-hidden">
      {header || <ListingHeader breadcrumbs={breadcrumbs} />}
      <div
        id="listing-scroller"
        className="flex flex-col mt-[72px] mb-[72px] overflow-auto h-screen"
      >
        <div className="container h-full">
          <div
            className={cn(
              "flex flex-col justify-center py-8 md:pt-20 h-full",
              containerClass
            )}
          >
            {children}
          </div>
        </div>
      </div>
      {footer}
    </div>
  );
};

export default memo(ListingPage);
