import React, { useEffect } from "react";
import { useParams } from "react-router";
import Bikes from "../ListingSteps/Bikes";
import Windsurfing from "../ListingSteps/Windsurfing";
import Kitesurfing from "../ListingSteps/Kitesurfing";
import { useCreateListingContext } from "contexts/create-listing";

const STEP_NUMBER = 2;

const componentsMap = {
  bikes: Bikes,
  ["road-bikes"]: Bikes,
  windsurfing: Windsurfing,
  kitesurfing: Kitesurfing,
};

const SelectListingType = () => {
  const { setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);
  const params = useParams();

  if (!params.typeId) {
    params.typeId = "bikes";
  }

  const StepComponent = componentsMap[params.typeId];

  return <StepComponent />;
};

export default SelectListingType;
