import React from "react";
import cn from "classnames";

const HintText = ({ className, children }) => {
  return (
    <p className={cn("text-sm font-normal text-[#475467]", className)}>
      {children}
    </p>
  );
};

export default HintText;
