import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";

const ReactConfetti = ({ numberOfPieces = 500, onComplete }) => {
  const [party, setParty] = useState(false);
  useEffect(() => {
    setParty(true);
  }, []);
  return (
    <Confetti
      style={{ zIndex: 0 }}
      recycle={false}
      numberOfPieces={party ? 500 : 0}
      onConfettiComplete={(confetti) => {
        onComplete?.();
        setParty(false);
        confetti.reset();
      }}
    />
  );
};

export default ReactConfetti;
