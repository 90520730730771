import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { ReactComponent as HomeIcon } from "../../icons/home.svg";
import styles from "./Breadcrumbs.module.sass";

const Breadcrumbs = ({ className, items }) => {
  return (
    <div className={cn(className, styles.breadcrumbs)}>
      <Link to="/" className={cn("flex items-center", styles.home)}>
        <HomeIcon />
      </Link>
      {items.map((x, index) => (
        <div className={styles.item} key={index}>
          {x.url ? (
            <Link className={styles.link} to={x.url}>
              {x.title}
            </Link>
          ) : (
            x.title
          )}
        </div>
      ))}
    </div>
  );
};

export default Breadcrumbs;
