import React, { useEffect } from "react";

import ListingPage from "../../../../components/Listings/ListingPage";
import ListingCard from "../../../../components/Listings/ListingCard";
import ListingFooter from "../../../../components/Listings/ListingFooter";
import { useCreateListingContext } from "contexts/create-listing";
import { useNavigate, useParams } from "react-router";
import useReviewMode from "../ListingSteps/Bikes/useReviewMode";
import { useFormContext } from "react-hook-form";
import { createListingFormKeys } from "contexts/create-listing/validation";

export const LISTINGS = [
  {
    id: "bikes",
    title: "Bikes",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/listing-e-bike.png",
  },
  {
    id: "windsurfing",
    title: "Windsurfing",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/listing-windsurfing.png",
  },
  {
    id: "kitesurfing",
    title: "Kitesurfing",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/listing-kitesurfing.png",
  },
];

const STEP_NUMBER = 1;

const SelectListings = () => {
  const { setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const { isReviewMode, goToReview } = useReviewMode();
  const navigate = useNavigate();
  const { listingId } = useParams();
  const { watch, setValue } = useFormContext();
  const selectedListing = watch(createListingFormKeys.listing);

  const onSelectListingType = React.useCallback(
    (type) => {
      if (isReviewMode) {
        goToReview();
        return;
      }
      setValue(createListingFormKeys.listing, type);
      setCurrentStep(2);
      navigate(`/become-a-seller/${listingId}/select-listing/${type}`);
    },
    [isReviewMode, listingId]
  );

  return (
    <ListingPage
      footer={
        <ListingFooter
          disableNext={!selectedListing}
          backUrl="/"
          nextUrl={`/become-a-seller/${listingId}/select-listing/${selectedListing}`}
        />
      }
    >
      <div className="md:max-w-2xl mx-auto">
        <div className="flex flex-col items-start">
          <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold mb-5 md:mb-9">
            What would you like to list?
          </h1>
          <div className="flex flex-col w-full items-center gap-y-6 md:gap-y-0 md:flex-row md:gap-x-4">
            {LISTINGS.map((listing) => (
              <ListingCard
                key={listing.id}
                className="w-[170px]"
                type={listing.id}
                title={listing.title}
                description={listing.description}
                image={listing.image}
                selected={selectedListing === listing.id}
                onSelect={onSelectListingType}
              />
            ))}
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default SelectListings;
