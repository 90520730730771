import React from "react";
import cn from "lib/cn";

const Progress = ({ className = "", percentage }) => {
  return (
    <div
      className={cn("h-2 rounded-md flex-1 bg-primary", className)}
      style={{
        width: percentage,
      }}
    />
  );
};

export default Progress;
