import * as yup from "yup";

export const createListingFormKeys = {
  listing: "listing",
  listingType: "listingType",
  listingEquipments: {
    brandName: "listingEquipments.brandName",
    model: "listingEquipments.model",
    size: "listingEquipments.size",
    seats: "listingEquipments.seats",
    gender: "listingEquipments.gender",
    modelYear: "listingEquipments.modelYear",
  },
  listingFeatures: {
    categories: "listingFeatures.categories",
    features: "listingFeatures.features",
  },
  rentalDetails: {
    name: "rentalDetails.name",
    pickupLocation: "rentalDetails.pickupLocation",
    notes: "rentalDetails.notes",
    inventory: "rentalDetails.inventory",
    returnsAndCancellations: "rentalDetails.returnsAndCancellations",
  },
  photos: "photos",
  price: "price",
  pricingType: "pricingType",
  accessories: "accessories",
  discount: "discount",
};

const createListingSchema = yup.object().shape({
  // listing
  listing: yup.string().required("Required"),

  // listingType
  listingType: yup.string().required("Required"),

  // equipment details
  listingEquipments: yup.object().shape({
    brandName: yup.string().required("Required"),
    model: yup.string(),
    size: yup.string().required("Required"),
    seats: yup.number().required("Required"),
    gender: yup.string().required("Required"),
    modelYear: yup.array().of(yup.string()).required("Required"),
  }),

  // categories and features
  listingFeatures: yup.object().shape({
    categories: yup.array().of(yup.string()).required("Required"),
    features: yup.array().of(yup.string()).required("Required"),
  }),

  // rental details
  rentalDetails: yup.object().shape({
    name: yup.string().required("Required"),
    pickupLocation: yup.string().required("Required"),
    notes: yup.string(),
    inventory: yup.number(),
    returnsAndCancellations: yup.string(),
  }),

  // photos
  photos: yup.array(),

  // price
  price: yup.number().required("Required"),
  pricingType: yup.string(),
  accessories: yup.array(),

  // discount
  discount: yup.array().of(yup.string()),
});

export default createListingSchema;
