import React from "react";
import { Helmet } from "react-helmet-async";
import { Outlet } from "react-router";
import { SeoConfig } from "lib/seo/seo-config";
import useUnresolvedPath from "hooks/useUnresolvedPath";

const defaults = {
  title: "Welcome",
};

const SeoLayout = () => {
  const pathname = useUnresolvedPath();

  const title = SeoConfig[pathname]?.title;

  return (
    <>
      <Helmet>
        <title>{`${title || defaults.title} | Good Hood River`}</title>
      </Helmet>
      <Outlet />
    </>
  );
};

export default SeoLayout;
