import React, { useEffect } from "react";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "../../../../../../components/Listings/ListingPage";
import ListingFooter from "../../../../../../components/Listings/ListingFooter";
import { useParams } from "react-router";
import FormLabel from "components/Form/Label";
import FormInput from "components/Form/Input";
import FormTextarea from "components/Form/Textarea";
import { useFormContext } from "react-hook-form";
import { createListingFormKeys } from "contexts/create-listing/validation";
import Counter from "components/Counter";
import getBikesBreadcrumb from "../breadcrumbs";
import HintText from "components/HintText";
import CustomReturnAndCancellation, {
  CUSTOM_RETURNS_AND_CANCELLATIONS_PRESETS,
} from "./components/CustomReturnsAndCancellation";

const STEP_NUMBER = 5;

const RentalDetails = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const [customReturnAndCancellation, setCustomReturnAndCancellation] =
    React.useState(false);

  const {
    watch,
    setValue,
    formState: { defaultValues },
    getValues,
  } = useFormContext();
  const name = watch(createListingFormKeys.rentalDetails.name);
  const pickupLocation = watch(
    createListingFormKeys.rentalDetails.pickupLocation
  );
  const inventory =
    watch(createListingFormKeys.rentalDetails.inventory) ||
    defaultValues.rentalDetails.inventory;

  const defaultName =
    watch(createListingFormKeys.listingEquipments.model) || "";

  const returnsAndCancellations =
    watch(createListingFormKeys.rentalDetails.returnsAndCancellations) || "";

  // sync customReeturnAndCancellation with returnsAndCancellations

  useEffect(() => {
    if (returnsAndCancellations) {
      setCustomReturnAndCancellation(
        !CUSTOM_RETURNS_AND_CANCELLATIONS_PRESETS.includes(
          returnsAndCancellations
        )
      );
    }
  }, [returnsAndCancellations]);

  const isValid = name && pickupLocation && inventory;

  return (
    <ListingPage
      containerClass="!h-auto"
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={!isValid}
          nextUrl={`/become-a-seller/12345/${params.typeId}/uploads`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto mt-10 md:mt-0">
        <div className="md:flex gap-16 w-full">
          <div className="flex flex-col gap-y-[24px] w-full">
            <div>
              <FormLabel required>Give a name</FormLabel>
              <FormInput
                name={createListingFormKeys.rentalDetails.name}
                autoFocus
                placeholder="e.g. Ride"
                defaultValue={defaultName}
              />
            </div>
            <div>
              <FormLabel required>Pickup location</FormLabel>
              <div className="relative">
                <FormInput
                  name={createListingFormKeys.rentalDetails.pickupLocation}
                  placeholder="e.g. Hood River Event Site, Portway Ave, Hood River"
                  className="pr-[100px] md:pr-[120px]"
                  iconRight={null}
                />
                {/* <p className="absolute top-0 right-0 translate-y-[50%] pr-3 font-semibold text-sm md:text-base leading-[22px] md:[24px]">
                  Google Map
                </p> */}
              </div>
            </div>
            <div>
              <FormLabel>Directions (if any)</FormLabel>
              <FormTextarea
                resizable={false}
                name={createListingFormKeys.rentalDetails.notes}
                maxRows={5}
                placeholder='e.g. "Next to Freshies coffee shop, ring the bell if no one around"'
              />
            </div>

            <div>
              <div className="flex items-center justify-between">
                <div className="flex flex-col">
                  <FormLabel>How many units would you like to list?</FormLabel>
                  <HintText>
                    {inventory} units will be displayed on your store's
                    inventory.{" "}
                    <a className="font-semibold text-text-link">Learn More</a>
                  </HintText>
                </div>
                <Counter
                  value={inventory}
                  setValue={(newInventory) => {
                    setValue(
                      createListingFormKeys.rentalDetails.inventory,
                      newInventory
                    );
                  }}
                  iconMinus="minus"
                  iconPlus="plus"
                  size="medium"
                />
              </div>
            </div>

            <div>
              <FormLabel>Returns and cancellations</FormLabel>
              <CustomReturnAndCancellation
                name={
                  createListingFormKeys.rentalDetails.returnsAndCancellations
                }
                selected={returnsAndCancellations}
                isCustom={customReturnAndCancellation}
                setIsCustom={setCustomReturnAndCancellation}
              />
              {customReturnAndCancellation && (
                <FormTextarea
                  resizable={false}
                  name={
                    createListingFormKeys.rentalDetails.returnsAndCancellations
                  }
                  maxRows={5}
                  placeholder='e.g. "In case of cancellation 80% will be refunded."'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default RentalDetails;
