import React from "react";
import cn from "classnames";
import styles from "./BackButton.module.sass";
import Icon from "../Icon";
import LinkButton from "../LinkButton";

const BackButton = ({
  className,
  to,
  title,
  size = "small",
  variant = "secondary",
}) => {
  return (
    <LinkButton
      to={to}
      variant={variant}
      size={size}
      className={cn("button-stroke button-small", styles.button, className)}
    >
      <Icon name="arrow-left" size="10" />
      <span>{title}</span>
    </LinkButton>
  );
};

export default BackButton;
