import React from "react";

const GHRLoader = () => {
  return (
    <img
      src="/images/ghr-white-bg.gif"
      alt="app-loader"
      className="h-full w-full object-contain"
    />
  );
};

export default GHRLoader;
