import React from "react";
import cn from "classnames";
import styles from "./Counter.module.sass";
import Icon from "../Icon";

const render = (value) => <div className={styles.number}>{value}</div>

const Counter = ({
  className,
  min,
  max,
  value,
  setValue,
  iconMinus,
  iconPlus,
  size = "small",
  onIncrement,
  onDecrement,
  renderProp = render,
}) => {
  return (
    <div className={cn(className, styles.counter)}>
      <button
        disabled={value === min}
        className={cn(
          styles.button,
          styles[size],
          "disabled:cursor-not-allowed",
          {
            [styles.disabled]: value === 0,
          }
        )}
        onClick={() => {
          if (onDecrement) {
            onDecrement();
            return;
          }
          let computed;
          if (!min) {
            computed = value - 1;
          } else if (value > min) {
            computed = value - 1;
          }
          setValue(computed);
        }}
      >
        <Icon
          name={iconMinus}
          size="24"
          style={{
            fill: max === value ? "var(--primary-color)" : null,
          }}
        />
      </button>
      {renderProp(value)}
      <button
        disabled={value === max}
        className={cn(
          styles.button,
          styles[size],
          "disabled:cursor-not-allowed"
        )}
        onClick={() => {
          if (onIncrement) {
            onIncrement();
            return;
          }
          let computed;
          if (!max) {
            computed = value + 1;
          } else if (value < max) {
            computed = value + 1;
          }
          setValue(computed);
        }}
      >
        <Icon
          name={iconPlus}
          size="24"
          style={{
            fill: min === value ? "var(--primary-color)" : null,
          }}
        />
      </button>
    </div>
  );
};

export default Counter;
