export const reverseObjectKeyValue = (
  object = {},
  key_prefix = "",
  value_prefix = ""
) =>
  Object.fromEntries(
    Object.entries(object).map(([key, value]) => [
      `${value_prefix}${value}`,
      `${key_prefix}${key}`,
    ])
  );
