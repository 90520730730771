import { createContext, useContext } from "react";

const CreateListingContext = createContext({
  steps: [],
  paths: [],
  currentStep: -1,
  setCurrentStep: () => {},
  directNavigation: false,
});

export const useCreateListingContext = () => useContext(CreateListingContext);

export default CreateListingContext;
