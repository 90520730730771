import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import cn from "classnames";
import { useDispatch, useSelector } from "react-redux";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { logoutAction } from "../../../store/slices/auth-slice";
import Button from "../../Button";
import Toast from "../../Toast/toast";

const User = ({ type = "customer", className, items }) => {
  const [visible, setVisible] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const onLogout = () => {
    setLogoutLoading(true);
    dispatch(logoutAction())
      .unwrap()
      .then(() => {
        Toast.success("Logout successfully");
      })
      .catch((error) => {
        console.log(error, "Error while logging out!");
      })
      .finally(() => setLogoutLoading(false));
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.user, className, { [styles.active]: visible })}>
        <button className={styles.head} onClick={() => setVisible(!visible)}>
          <img
            src={
              user.photoURL ||
              `https://ui-avatars.com/api/?name=${user.displayName}`
            }
            alt="Avatar"
          />
        </button>
        <div className={styles.body}>
          <div className={styles.group}>
            {items.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x, index) => (
                  <NavLink
                    className={({ isActive }) => {
                      return cn(styles.item, {
                        [styles.active]: isActive,
                      });
                    }}
                    to={x.url}
                    onClick={() => setVisible(!visible)}
                    key={index}
                  >
                    <div className={styles.icon}>
                      <Icon name={x.icon} size="24" />
                    </div>
                    <div className={styles.text}>{x.title}</div>
                  </NavLink>
                ))}
              </div>
            ))}
          </div>
          <div className={styles.btns}>
            <NavLink
              className={({ isActive }) => {
                return cn("button button-small", styles.button, {
                  [styles.active]: isActive,
                });
              }}
              to="/account-settings"
              onClick={() => setVisible(!visible)}
            >
              Account
            </NavLink>
            <Button
              loading={logoutLoading}
              className={cn("button-stroke button-small", styles.button)}
              onClick={onLogout}
            >
              Log out
            </Button>
          </div>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default User;
