import React, { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import Badge from "components/Badge";
import FormLabel from "components/Form/Label";
import { createListingFormKeys } from "contexts/create-listing/validation";
import Counter from "components/Counter";

const RANGE = 5;

export const ACCESSORIES = [
  { id: "helmet", value: "Helmet", price: 10, included: true },
  { id: "lock", value: "Lock", price: 15 },
  { id: "water-bottle", value: "Water Bottle", price: 20 },
];

const Accessories = () => {
  const { setValue, getValues } = useFormContext();
  const accessories = getValues(createListingFormKeys.accessories);

  useEffect(() => {
    if (accessories.length === 0) {
      setValue(createListingFormKeys.accessories, ACCESSORIES);
    }
  }, [accessories]);

  return (
    <>
      {accessories.map((accessory, index) => {
        return (
          <Accessory
            key={accessory.id}
            index={index}
            accessory={accessories[index]}
          />
        );
      })}
      <p className="flex justify-end items-center text-[10px]">
        Select price or Type and hit enter to set custom pricing
      </p>
    </>
  );
};

export default Accessories;

const Accessory = ({ index, accessory }) => {
  const { setValue, getValues, control } = useFormContext();
  const name = `${createListingFormKeys.accessories}.${index}.total`;
  const includedFieldName = `${createListingFormKeys.accessories}.${index}.included`;

  // add the same in the price as well
  const initialPrice = React.useRef(
    Number(getValues(createListingFormKeys.price))
  );

  return (
    <div className="py-4 flex items-center w-full">
      <div className="flex-1">
        <FormLabel>{accessory.value}</FormLabel>
      </div>
      <div className="flex items-center justify-center gap-x-4">
        <Badge
          onClick={() => {
            const includedBefore = accessory?.included;
            setValue(includedFieldName, !includedBefore);
          }}
          selected={accessory?.included}
        >
          Included?
        </Badge>
        {accessory?.included ? (
          <span>$0</span>
        ) : (
          <Controller
            name={name}
            control={control}
            defaultValue={0}
            render={({ field }) => (
              <Counter
                value={field.value}
                renderProp={() => {
                  // override onChange so that total can be set as number
                  return (
                    <input
                      type="number"
                      // for disabling wheel and arrow icons for number input
                      className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none w-[50px] text-center"
                      onWheel={() => document.activeElement.blur()}
                      {...field}
                      onKeyUp={(e) => {
                        if (e.code === "Enter") {
                          const accessories = getValues(
                            createListingFormKeys.accessories
                          );
                          const notIncludedAccessories = accessories.filter(
                            (accessory) => !accessory.included
                          );
                          const computedPrice = notIncludedAccessories.reduce(
                            (accumulator, currentValue) =>
                              accumulator + Number(currentValue.total || 0),
                            0
                          );
                          setValue(
                            createListingFormKeys.price,
                            Number(initialPrice.current) + computedPrice
                          );
                          // on blur the input at the end
                          field.onBlur();
                        }
                      }}
                      onChange={(e) => {
                        // allow max of 4 digits
                        const newValue = parseFloat(e.target.value.slice(0, 4));
                        field.onChange(newValue);
                      }}
                    />
                  );
                }}
                onIncrement={() => {
                  const newTotal = Number(field.value + RANGE);
                  setValue(name, newTotal);

                  // calculate price
                  const price = Number(getValues(createListingFormKeys.price));
                  setValue(createListingFormKeys.price, price + RANGE);
                }}
                onDecrement={() => {
                  if (field.value > 0) {
                    const newTotal = Number(field.value - RANGE);
                    setValue(name, newTotal);

                    // calculate price
                    const price = Number(
                      getValues(createListingFormKeys.price)
                    );
                    setValue(createListingFormKeys.price, price - RANGE);
                  }
                }}
                iconMinus="minus"
                iconPlus="plus"
                size="medium"
              />
            )}
          />
        )}
      </div>
    </div>
  );
};
