import React from "react";
import { Outlet, useLocation } from "react-router";
import Page from "../components/Page";
import { useSelector } from "react-redux";

const separatorHeaderRoutes = [
  "/stays-product",
  "/stays-checkout",
  "/stays-checkout-complete",
  "/full-photo",

  "/product",
  "/checkout",
  "/checkout-complete",
  "/cars-product",
  "/cars-checkout",

  "/cars-checkout-complete",
  "/messages",
  "/wishlists",
  "/bookings",
  "/your-trips",
  "/list-your-property",
];
const footerHideRoutes = ["/messages"];
const wideRoutes = ["/messages"];

const CustomerLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  const separatorHeader = separatorHeaderRoutes.includes(location.pathname);
  const footerHide = footerHideRoutes.includes(location.pathname);
  const wide = wideRoutes.includes(location.pathname);

  // if (!user) {
  //   return <Navigate to="/" replace />;
  // }

  return (
    <Page
      notAuthorized={!Boolean(user)}
      footerHide={footerHide}
      separatorHeader={separatorHeader}
      wide={wide}
    >
      <Outlet />
    </Page>
  );
};
export default CustomerLayout;
