import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { firebaseConfig } from "../lib/firebase/firebase-config";

export const parseJwt = (token) => {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const useGoogleTapSignIn = (opt) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  const { parentContainerId, callback } = opt || {};

  const isSignedIn = Boolean(user);

  const activateGoogleTapSignIn = useMemo(() => {
    if (opt.activateOnRoutes && Array.isArray(opt.activateOnRoutes)) {
      return opt.activateOnRoutes.some((path) => location.pathname === path);
    }
    return true;
  }, [opt.activateOnRoutes, location.pathname]);

  useEffect(() => {
    const { google } = window;
    if (activateGoogleTapSignIn && !opt.loading && !isSignedIn && google) {
      google.accounts.id.initialize({
        client_id: firebaseConfig.clientId,
        cancel_on_tap_outside: false,
        callback: async (response) => {
          if (!opt?.redirect) {
            // response is here
            // {
            //  "iss": "https://accounts.google.com",
            //  "azp": "163470548673-csb62p2tlpft8rgqse1mhk5oo3mgi9lm.apps.googleusercontent.com",
            //  "aud": "163470548673-csb62p2tlpft8rgqse1mhk5oo3mgi9lm.apps.googleusercontent.com",
            //  "sub": "103266527999413470902",
            //  "hd": "connectingpointstech.com",
            //  "email": "amandeep@connectingpointstech.com",
            //  "email_verified": true,
            //  "nbf": 1700232996,
            //  "name": "Amandeep Kochhar",
            //  "picture": "https://lh3.googleusercontent.com/a/ACg8ocJX_0dHT7fuRzvqnlmuU-FyeVTjq-BfSL-t2e7TJrw6=s96-c",
            //  "given_name": "Amandeep",
            //  "family_name": "Kochhar",
            //  "locale": "en",
            //  "iat": 1700233296,
            //  "exp": 1700236896,
            //  "jti": "e30e30e0f4af2bbab9174f9b583e19ddab82dbf7"
            // }

            callback?.(response.credential);
          }
        },
        prompt_parent_id: parentContainerId,
      });
      google.accounts.id.renderButton(
        document.getElementById("googleSignInWrapper"),
        {
          theme: "outline",
          size: "large",
          type: "standard",
          shape: "rectangular",
          text: "signin_with",
          logo_alignment: "center",
          width: "350px",
        } // customization attributes
      );

      // Here we just console.log some error situations and reason why the google one tap
      // is not displayed. You may want to handle it depending on yuor application
      google.accounts.id.prompt((notification) => {
        if (notification.isNotDisplayed()) {
          console.log(notification.getNotDisplayedReason());
        } else if (notification.isSkippedMoment()) {
          console.log(notification.getSkippedReason());
        } else if (notification.isDismissedMoment()) {
          console.log(notification.getDismissedReason());
        }
      });
    }
  }, [
    activateGoogleTapSignIn,
    opt.loading,
    isSignedIn,
    parentContainerId,
    opt,
    callback,
  ]);

  useEffect(() => {
    const { google } = window;
    return () => {
      google?.accounts?.id?.cancel();
    };
  }, [activateGoogleTapSignIn, opt.loading]);
};

export default useGoogleTapSignIn;
