import React, { useState } from "react";
import cn from "lib/cn";
import TextareaAutosize from "react-textarea-autosize";

const Textarea = ({
  label,
  name,
  rows = 1,
  maxRows = 1,
  resizable = false,
  required = false,
  maxLength,
  error,
  onChange,
  ...rest
}) => {
  const [characterCount, setCharacterCount] = useState(0);

  const inputId = React.useMemo(() => `input-${name}`, [name]);

  return (
    <>
      {label && (
        <label
          htmlFor={inputId}
          className={cn("block text-sm font-medium leading-6 text-gray-900", {
            asterisk: required,
          })}
        >
          {label}
        </label>
      )}

      <div className="relative">
        <TextareaAutosize
          rows={rows}
          maxRows={maxRows}
          id={inputId}
          maxLength={maxLength}
          className={cn(
            "block w-full rounded-md py-[10px] px-[14px] font-normal text-base border-0 pr-10 text-gray-900 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset ring-gray-300 placeholder:text-[#667085] focus:ring-primary sm:text-sm sm:leading-6",
            {
              "text-red-900 ring-red-300 placeholder:text-red-400 focus:ring-red-500":
                error,
              "resize-none": !resizable,
            }
          )}
          onChange={(event) => {
            setCharacterCount(event.target.value.length);
            onChange(event);
          }}
          {...rest}
        />
        {maxLength && (
          <p className="text-xs text-right mt-1">
            {characterCount} / {maxLength}
          </p>
        )}
      </div>

      {error && <p className="text-xs text-red-500 mt-1">{error.message}</p>}
    </>
  );
};

export default Textarea;
