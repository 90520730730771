import React from "react";
import cn from "classnames";

const Checkbox = ({ className, ...rest }, ref) => {
  return (
    <input
      ref={ref}
      type="checkbox"
      className={cn("h-4 w-4 accent-primary", className)}
      {...rest}
    />
  );
};

export default React.forwardRef(Checkbox);
