import EquipmentPrice from "containers/Listings/CreateListing/ListingSteps/Bikes/EquipmentPrice";
import CategoriesAndFeatures from "containers/Listings/CreateListing/ListingSteps/Bikes/CategoriesAndFeatures";
import BikeEquipmentDetails from "containers/Listings/CreateListing/ListingSteps/Bikes/EquipmentDetails";
import RentalDetails from "containers/Listings/CreateListing/ListingSteps/Bikes/RentalDetails";
import SelectListings from "containers/Listings/CreateListing/SelectListing";
import SelectListingType from "containers/Listings/CreateListing/SelectListingType";
import Discount from "containers/Listings/CreateListing/ListingSteps/Bikes/Discounts";
import Availability from "containers/Listings/CreateListing/ListingSteps/Bikes/Availability";
import Review from "containers/Listings/CreateListing/ListingSteps/Bikes/Review";
import EquipmentPhotos from "containers/Listings/CreateListing/ListingSteps/Bikes/EquipmentPhotos";

export const LISTING_ROUTES = [
  {
    step: 1,
    path: "/become-a-seller/:listingId/select-listing",
    element: <SelectListings />,
  },
  {
    step: 2,
    path: "/become-a-seller/:listingId/select-listing/:typeId",
    element: <SelectListingType />,
  },
  {
    step: 3,
    path: "/become-a-seller/:listingId/:typeId/equipment-details",
    element: <BikeEquipmentDetails />,
  },
  {
    step: 4,
    path: "/become-a-seller/:listingId/:typeId/equipment-features",
    element: <CategoriesAndFeatures />,
  },
  {
    step: 5,
    path: "/become-a-seller/:listingId/:typeId/rental-details",
    element: <RentalDetails />,
  },
  {
    step: 6,
    path: "/become-a-seller/:listingId/:typeId/uploads",
    element: <EquipmentPhotos />,
  },
  {
    step: 7,
    path: "/become-a-seller/:listingId/:typeId/price",
    element: <EquipmentPrice />,
  },
  {
    step: 8,
    path: "/become-a-seller/:listingId/:typeId/discount",
    element: <Discount />,
  },
  {
    step: 9,
    path: "/become-a-seller/:listingId/:typeId/availability",
    element: <Availability />,
  },
  {
    step: 10,
    path: "/become-a-seller/:listingId/:typeId/review",
    element: <Review />,
  },
];
