import React from "react";
import { Outlet } from "react-router";
import CreateListingProvider from "contexts/create-listing/ListingProvider";

const ListingLayout = () => {
  return (
    <CreateListingProvider>
      <Outlet />
    </CreateListingProvider>
  );
};

export default ListingLayout;
