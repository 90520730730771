import React from "react";
import cn from "classnames";
import Icon from "../Icon";

const Badge = ({ selected, rightIcon, onClick, className, children }) => {
  return (
    <button
      className={cn(
        "flex justify-center items-center rounded-full px-3 py-1 border border-1 border-solid border-[#EAECF0] bg-[#F9FAFB] font-medium text-sm text-gray-700",
        className,
        {
          "bg-black text-white": selected,
        }
      )}
      onClick={onClick}
    >
      {children}
      {selected && (
        <Icon name="check" className="ml-2" size="10" fill="white" />
      )}
      {rightIcon}
    </button>
  );
};

export default Badge;
