import React, { useEffect, useState } from "react";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import { useParams } from "react-router";
import cn from "classnames";
import { ReactComponent as EditIcon } from "icons/edit.svg";
import { ReactComponent as ChevronDownIcon } from "icons/chevron-down.svg";
import Badge from "components/Badge";
import Divider from "components/Divider";
import Icon from "components/Icon";
import { useFormContext } from "react-hook-form";
import { createListingFormKeys } from "contexts/create-listing/validation";
import getBikesBreadcrumb from "../breadcrumbs";
import ButtonGroup from "components/Form/ButtonGroup";
import FormLabel from "components/Form/Label";
import Accessories from "./components/Accessories";

const STEP_NUMBER = 7;
export const PricingType = [
  { label: "Per hour", value: "per_hour" },
  { label: "Per day", value: "per_day" },
];

export const ACCESSORIES = [
  { id: "helmet", value: "Helmet", price: 10 },
  { id: "lock", value: "Lock", price: 15 },
  { id: "water-bottle", value: "Water Bottle", price: 20 },
];

const EquipmentPrice = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();

  const [detailedView, setDetailedView] = useState(false);
  const [editPrice, setEditPrice] = useState(true);

  const toggleView = () => setDetailedView((p) => !p);
  const toggleEditPrice = () => setEditPrice((p) => !p);

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const {
    watch,
    register,
    formState: { defaultValues },
  } = useFormContext();
  const price = watch(createListingFormKeys.price);

  return (
    <ListingPage
      containerClass="!h-auto"
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={!price}
          nextUrl={`/become-a-seller/12345/${params.typeId}/discount`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto mt-16 md:mt-0 max-w-[600px]">
        <div className="flex flex-col items-center">
          <h1 className="text-2xl font-bold text-[#344054] mb-[10px]">
            Now, set your price
          </h1>
          <p className="text-sm font-medium text-[#344054]">
            You can change it anytime
          </p>
        </div>

        <div
          className={cn("mt-[80px]", {
            "mb-[80px]": !detailedView,
          })}
        >
          <div className="flex flex-col items-center">
            <div className="flex justify-center items-end">
              {editPrice ? (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setEditPrice(false);
                  }}
                >
                  <span className="text-[72px] leading-[90px] font-[700] text-[#101828]">
                    $
                  </span>
                  <input
                    {...register(createListingFormKeys.price)}
                    autoFocus
                    size={String(price).length > 0 ? String(price).length : 1}
                    className="text-[72px] leading-[90px] font-[700] text-[#101828]"
                  />
                </form>
              ) : (
                <p className="text-[72px] leading-[90px] font-[700] text-[#101828]">
                  ${price}
                </p>
              )}
              <button
                className="ml-[10px] rounded-full border border-solid border-[#D0D5DD] p-2"
                onClick={toggleEditPrice}
              >
                {editPrice ? <Icon name="tick" size="20" /> : <EditIcon />}
              </button>
            </div>

            {detailedView ? (
              <div className="flex flex-col gap-y-[10px] mt-[10px] w-full">
                <div className="border border-solid border-1 border-[#D0D5DD] py-2 px-3 rounded-[8px] shadow-default">
                  <div className="flex items-center justify-between mb-[8px] font-normal text-base text-[#667085]">
                    <p className="flex-1 text-ellipsis">Base Price</p>
                    <p>$451</p>
                  </div>
                  <div className="flex items-center justify-between font-normal text-base text-[#667085]">
                    <p className="flex-1 text-ellipsis">Base service fee</p>
                    <p>$64</p>
                  </div>
                  <Divider containerClass="my-0 py-[8px]" />
                  <div className="flex items-center justify-between text-base font-medium text-[#344054]">
                    <p className="flex-1 text-ellipsis">Price before taxes</p>
                    <p>$515</p>
                  </div>
                </div>
                <div className="border border-solid border-1 border-[#D0D5DD] py-2 px-3 rounded-[8px] shadow-default">
                  <div className="flex items-center justify-between font-normal text-base text-[#667085]">
                    <p className="flex-1">You earn</p>
                    <p>$451</p>
                  </div>
                </div>
                <div className="flex flex-col items-center mt-6">
                  <button
                    className="text-sm font-bold text-[#475467]"
                    onClick={toggleView}
                  >
                    Show Less
                    <ChevronDownIcon
                      color="#475467"
                      className="ml-1 rotate-180 h-5 w-5"
                    />
                  </button>
                </div>
              </div>
            ) : (
              <>
                <div className="my-[10px]">
                  <ButtonGroup
                    name={createListingFormKeys.pricingType}
                    defaultValue={defaultValues.pricingType}
                    items={PricingType}
                  />
                </div>
                <div className="flex">
                  <p className="font-sm font-medium text-[#344054] mr-[10px]">
                    Price before taxes $515
                  </p>
                  <button onClick={toggleView}>
                    <ChevronDownIcon color="#475467" className="h-5 w-5" />
                  </button>
                </div>
                <div className="border border-solid border-1 border-[#D0D5DD] py-3 px-5 rounded-[8px] shadow-default my-16 w-full">
                  <div className="py-1">
                    <FormLabel variant="primary">Accessories</FormLabel>
                  </div>
                  <Divider />
                  <Accessories />
                </div>

                <div className="flex flex-col items-center gap-y-[10px]">
                  <div>
                    <Badge>
                      <p className="text-sm font-medium text-[#101828]">
                        Compare similar listings $79-$119
                      </p>
                    </Badge>
                  </div>
                  <div>
                    <a className="text-sm font-medium underline text-[#344054]">
                      Learn more about pricing
                    </a>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default EquipmentPrice;
