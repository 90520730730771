import { useLocation, useParams } from "react-router";
import { reverseObjectKeyValue } from "utils/object-functions";
import { getUnresolvedPath } from "utils/string-functions";

const useUnresolvedPath = () => {
  const { pathname } = useLocation();
  const params = useParams();

  const pathHasParams = Object.keys(params).length > 0;

  if (pathHasParams) {
    const reversedParams = reverseObjectKeyValue(params);
    return getUnresolvedPath(pathname, reversedParams);
  }

  return pathname;
};

export default useUnresolvedPath;
