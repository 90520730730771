export const firebaseConfig = {
  apiKey:
    process.env.FIREBASE_API_KEY || "AIzaSyBUi4M-qxPMWjQgyH20oRPr0_0da7Fmvn4",
  authDomain:
    process.env.FIREBASE_AUTH_DOMAIN ||
    "metal-dimension-404006.firebaseapp.com",
  projectId: process.env.FIREBASE_PROJECT_ID || "metal-dimension-404006",
  storageBucket:
    process.env.FIREBASE_STORAGE_BUCKET || "metal-dimension-404006.appspot.com",
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID || "163470548673",
  appId:
    process.env.FIREBASE_APP_ID || "1:163470548673:web:f959360c65bae4035dfe40",
  measurementId: process.env.FIREBASE_MEASUREMENT_ID || "G-7WNZQHQR96",
  // recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY,
  clientId:
    "163470548673-csb62p2tlpft8rgqse1mhk5oo3mgi9lm.apps.googleusercontent.com",
  clientSecret: "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",
};

console.log(firebaseConfig, "firebaseConfig");
