import React from "react";

const Windsurfing = () => {
  return (
    <>
      <h1>What kind of Windsurfing?</h1>
    </>
  );
};

export default Windsurfing;
