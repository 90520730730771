import React from "react";
import GHRLoader from "./GHRLoader";

const AppLoader = () => {
  return (
    <div className="h-screen w-screen flex flex-col gap-y-2 md:gap-y-3 justify-center items-center">
      <div className="h-20 w-20 sm:h-24 sm:w-24 lg:h-30 lg:w-30 xl:h-36 md:w-36">
        <GHRLoader />
      </div>
      <p className="text-center font-medium text-base">Working on it...</p>
    </div>
  );
};

export default AppLoader;
