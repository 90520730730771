// import { useEffect } from "react";

// const useScrollPosition = (pageKey, targetEl) => {
//   const key = `scrollPos_${pageKey}`;

//   useEffect(() => {
//     // Store scroll position when leaving the page
//     const onScroll = () => {
//       localStorage.setItem(key, targetEl.scrollTop);
//     };

//     targetEl?.addEventListener("scroll", onScroll);

//     return () => {
//       targetEl?.removeEventListener("scroll", onScroll);
//     };
//   }, [key, targetEl]);

//   useEffect(() => {
//     // Retrieve and scroll to the stored position when returning to the page
//     const scrollPos = localStorage.getItem(key) || 0;
//     if (targetEl) {
//       targetEl.scrollTop = parseInt(scrollPos, 10);
//     }
//   }, [key, targetEl]);
// };

// export default useScrollPosition;

import { useEffect } from "react";

const useScrollPosition = (pageKey, targetEl) => {
  const key = `scrollPos_${pageKey}`;

  useEffect(() => {
    // Store scroll position when leaving the page
    const onScroll = () => {
      localStorage.setItem(key, targetEl.scrollTop);
    };

    targetEl?.addEventListener("scroll", onScroll);

    return () => {
      targetEl?.removeEventListener("scroll", onScroll);
    };
  }, [key, targetEl]);

  // Run this effect once when the component mounts
  useEffect(() => {
    // Retrieve and scroll to the stored position when returning to the page
    const scrollPos = localStorage.getItem(key) || 0;
    if (targetEl) {
      targetEl.scrollTop = parseInt(scrollPos, 10);
    }
  }, []); // Empty dependency array

  // Cleanup function to remove the stored scroll position when the component unmounts
  // useEffect(() => {
  //   return () => {
  //     localStorage.removeItem(key);
  //   };
  // }, [key]);
};

export default useScrollPosition;

