import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";

const LinkButton = ({
  to = "#", // destination
  size = "small", // small or large
  variant = "secondary", // primary or secondary
  loading = false,
  className = "",
  loadingComponent,
  onClick,
  children,
}) => {
  return (
    <Link to={!loading && to} onClick={onClick}>
      <Button
        variant={variant}
        loading={loading}
        size={size}
        className={className}
        loadingComponent={loadingComponent}
      >
        {!loading && children}
      </Button>
    </Link>
  );
};

export default LinkButton;
