export const ALL_BIKE_STEPS = {
  Listing: {
    step: 1,
    path: "/become-a-seller/:listingId/select-listing",
  },
  ListingType: {
    step: 2,
    path: "/become-a-seller/:listingId/select-listing/:typeId",
  },
  BasicDetails: {
    step: 3,
    path: "/become-a-seller/:listingId/:typeId/equipment-details",
  },
  CategoriesAndFeatures: {
    step: 4,
    path: "/become-a-seller/:listingId/:typeId/equipment-features",
  },
  RentalDetails: {
    step: 5,
    path: "/become-a-seller/:listingId/:typeId/rental-details",
  },
  Price: {
    step: 6,
    path: "/become-a-seller/:listingId/:typeId/price",
  },
  Discount: {
    step: 7,
    path: "/become-a-seller/:listingId/:typeId/discount",
  },
  Availability: {
    step: 8,
    path: "/become-a-seller/:listingId/:typeId/availability",
  },
  Review: {
    step: 9,
    path: "/become-a-seller/:listingId/:typeId/review",
  },
};

const steps = {};

Object.values(ALL_BIKE_STEPS).forEach((route) => {
  steps[route.step] = route.path;
});

export const getPathByStep = (step) => {
  return steps[step];
};
