import React from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import ListingCard from "components/Listings/ListingCard";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import { createListingFormKeys } from "contexts/create-listing/validation";
import useReviewMode from "./useReviewMode";
import { useCreateListingContext } from "contexts/create-listing";
import getBikesBreadcrumb from "./breadcrumbs";

export const BIKES = [
  {
    id: "road-bikes",
    title: "Road bikes",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/road_bikes.png",
  },
  {
    id: "mountain-bikes",
    title: "Mountain bikes",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/mountain_bikes.png",
  },
  {
    id: "E-bikes",
    title: "E bikes",
    description: "Good for a sunny day ride on your favorite bike path",
    image: "/images/listings/e-bike.png",
  },
];

const Bikes = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { currentStep } = useCreateListingContext();

  const { watch, setValue } = useFormContext();
  const listingType = watch(createListingFormKeys.listingType);
  const { isReviewMode, goToReview } = useReviewMode();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      // listingId: "12345",
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const onSelect = (type) => {
    if (isReviewMode) {
      goToReview();
      return;
    }
    setValue(createListingFormKeys.listingType, type);
    navigate(`/become-a-seller/12345/${type}/equipment-details`);
  };

  return (
    <ListingPage
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={!listingType}
          nextUrl={`/become-a-seller/12345/${params.typeId}/equipment-details`}
        />
      }
    >
      <div className="md:max-w-2xl mx-auto">
        <div className="flex flex-col items-start">
          <h1 className="text-xl md:text-2xl lg:text-4xl font-semibold mb-5 mt-2 md:mt-0 md:mb-9">
            What kind of bike?
          </h1>
          <div className="flex flex-col w-full items-center gap-y-6 md:gap-y-0 md:flex-row md:gap-x-4">
            {BIKES.map((bike) => (
              <ListingCard
                key={bike.id}
                className="w-[170px]"
                type={bike.id}
                title={bike.title}
                description={bike.description}
                image={bike.image}
                selected={listingType === bike.id}
                onSelect={onSelect}
              />
            ))}
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default Bikes;
