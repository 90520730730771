import React from "react";
import { ReactComponent as UploadIcon } from "icons/upload-cloud.svg";
import HintText from "components/HintText";
import cn from "lib/cn";

const FileUploader = ({ active = false, onOpen }) => {
  return (
    <div
      className={cn(
        "border border-solid border-gray-100 rounded-lg p-4 w-full",
        {
          "border-primary border-dashed": active,
        }
      )}
    >
      <div className="flex flex-col gap-y-[10px] items-center">
        <div className="p-[10px] border border-solid border-gray-100 rounded-md shadow-sm cursor-pointer">
          <UploadIcon onClick={onOpen} />
        </div>
        <div>
          <HintText className="text-center">
            {active ? (
              <p>Drop now</p>
            ) : (
              <>
                <a className="font-semibold text-text-link" onClick={onOpen}>
                  Click to upload
                </a>{" "}
                or drag and drop
              </>
            )}
          </HintText>
          <HintText className="text-sm mt-1">
            SVG, PNG, or GIF <span className="text-xs">(max. size 25 mb)</span>
          </HintText>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
