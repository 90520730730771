import React, { memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../Button";
import Breadcrumbs from "components/Breadcrumbs";
import { persistListing } from "contexts/create-listing/utils";
import { useFormContext } from "react-hook-form";
import { useCreateListingContext } from "contexts/create-listing";

const ListingHeader = ({ breadcrumbs = [] }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getValues } = useFormContext();
  const { currentStep } = useCreateListingContext();

  return (
    <header className="bg-white fixed top-0 w-full z-50 h-[72px]  border-b border-b-gray-300 border-solid">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="relative flex justify-between items-center lg:gap-8 py-4">
          <div className="flex md:inset-y-0 md:left-0 lg:static xl:col-span-2">
            <div className="flex flex-shrink-0 items-center">
              <Link to="/">
                <img
                  className="h-8 w-auto"
                  src="/images/logo.png"
                  alt="Your Company"
                />
              </Link>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <div className="flex items-center gap-x-2 md:gap-x-6">
              <Button variant="secondary">Questions?</Button>
              <Button
                onClick={() => {
                  persistListing(
                    getValues(),
                    location.pathname,
                    currentStep,
                    "draft"
                  );
                  navigate("/vendor/listings");
                }}
                variant="secondary"
              >
                Save &amp; Exit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {breadcrumbs?.length > 0 && (
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white w-full">
          <Breadcrumbs className="py-2 md:py-[12px]" items={breadcrumbs} />
        </div>
      )}
    </header>
  );
};

export default memo(ListingHeader);
