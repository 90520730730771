import { useCallback } from "react";
import {
  useSearchParams,
  useNavigate,
  generatePath,
  useParams,
} from "react-router-dom";
import { ALL_BIKE_STEPS } from "./steps";
import { useCreateListingContext } from "contexts/create-listing";

const useReviewMode = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const { setCurrentStep } = useCreateListingContext();

  const isReviewMode = searchParams.get("mode") === "review";

  const goToReview = useCallback(() => {
    const path = generatePath(ALL_BIKE_STEPS.Review.path, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
    setCurrentStep(ALL_BIKE_STEPS.Review.step);
    navigate(path);
  }, [params, navigate, setCurrentStep]);

  return {
    isReviewMode,
    goToReview,
  };
};

export default useReviewMode;
