import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  clearPersistedListings,
  getPersistedListings,
} from "contexts/create-listing/utils";
import HintText from "components/HintText";
import FormLabel from "components/Form/Label";
import { ReactComponent as PlusIcon } from "icons/plus.svg";
import { ReactComponent as ListIcon } from "icons/list.svg";
import IconButton from "components/IconButton";
import ReviewListing from "components/Cards/ReviewListing";

const ListListings = () => {
  const persistedListings = getPersistedListings();
  const navigate = useNavigate();
  const [listings, setListings] = useState(Boolean(persistedListings));

  return (
    <div className="my-4">
      {listings &&
      (persistedListings?.data?.listing ||
        persistedListings?.status === "draft") ? (
        <>
          <h1 className="text-lg font-semibold">Draft listings</h1>
          <div className="flex items-center">
            <HintText className="mr-2">
              You have following listing in draft
            </HintText>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a
                className="font-semibold text-red-600 ml-3"
                onClick={() => {
                  clearPersistedListings();
                  setListings(false);
                }}
              >
                Clear
              </a>
            </div>
          </div>
          <div className="flex flex-col px-4 py-3 shadow-sm border border-solid border-gray-100 rounded-md w-full mt-4">
            <FormLabel className="uppercase">
              {persistedListings?.data?.listing || "Select a type"}
            </FormLabel>
            <div className="flex justify-between gap-x-2">
              <FormLabel variant="primary" className="!text-base !flex-1">
                {persistedListings?.data?.listingEquipments?.model || "N/A"}
              </FormLabel>

              <button
                className="font-bold text-text-link"
                onClick={() => navigate(persistedListings?.url)}
              >
                Restore
              </button>
            </div>
          </div>

          <section className="mt-16">
            <div className="flex justify-between items-center">
              <h1 className="text-text-secondary text-2xl flex-1">
                Your Listings
              </h1>
              <div className="flex gap-x-5 items-center">
                <IconButton
                  tooltip="Add new listing"
                  onClick={() => navigate("/become-a-seller")}
                >
                  <PlusIcon />
                </IconButton>
                <IconButton>
                  <ListIcon />
                </IconButton>
              </div>
            </div>

            <div className="mt-8 flex gap-2">
              <ReviewListing
                image="/images/listings/road_bikes.png"
                brand="Nike"
                title="Road bike"
                category="Category"
                priceOld="55"
                priceActual={40}
                categoryText="Pending Review"
                gender="Unisex"
                className="w-1/3"
              />
              <ReviewListing
                image="/images/listings/road_bikes.png"
                brand="Nike"
                title="Road bike"
                category="Category"
                priceOld="55"
                priceActual={40}
                categoryText="Pending Review"
                gender="Unisex"
                className="w-1/3"
              />
              <ReviewListing
                image="/images/listings/road_bikes.png"
                brand="Nike"
                title="Road bike"
                category="Category"
                priceOld="55"
                priceActual={40}
                categoryText="Pending Review"
                gender="Unisex"
                className="w-1/3"
              />
            </div>
          </section>
        </>
      ) : (
        <div className="flex flex-col items-center">
          <div>
            <h1 className="text-lg font-semibold mb-2">
              No draft listing found!
            </h1>
            <HintText className="mr-2">Lets create a new listing</HintText>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              className="font-semibold text-text-link"
              onClick={() => {
                navigate("/become-a-seller");
              }}
            >
              Create Listing
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListListings;
