import React from "react";
import cn from "classnames";
import Spinner from "../Spinner";
import styles from "./Button.module.sass";

const Button = ({
  className = "",
  loading,
  children,
  variant, // primary or secondary
  size = "small", // small or large
  loadingComponent,
  ...rest
}) => {
  let loadingComponentProps = {};
  if (loading) {
    loadingComponentProps = {
      style: {
        height: 20,
        width: 20,
        border: "2px solid #FFF",
        borderBottomColor: "#777E90",
      },
    };
  }

  return (
    <button
      className={cn(
        "button bg-primary disabled:bg-[#ccc] disabled:hover:bg-[#ccc] disabled:cursor-not-allowed",
        className,
        {
          "button-stroke": variant === "secondary",
          "button-small": size === "small",
          [styles[variant]]: Boolean(variant),
        }
      )}
      {...rest}
    >
      {loading
        ? loadingComponent || <Spinner {...loadingComponentProps} />
        : children}
    </button>
  );
};

export default React.memo(Button);

const ButtonGroup = ({ items = [], value = "", onChange }) => {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      {items.map((item, index) => {
        const isFirst = items.length === 1 ? true : index === 0;
        const isLast = items.length === 1 ? true : index === items.length - 1;

        return (
          <button
            key={item.value}
            type="button"
            className={cn(
              "relative inline-flex items-center bg-white px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10",
              {
                "rounded-l-md": isFirst,
                "rounded-r-md": isLast,
                "!bg-primary text-white": value === item.value,
              }
            )}
            onClick={() => {
              // setSelected(item.value);
              onChange(item.value);
            }}
          >
            {item.label}
          </button>
        );
      })}
    </span>
  );
};

export { ButtonGroup };
