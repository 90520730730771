import React, { useEffect } from "react";
import cn from "classnames";
import { ReactComponent as EyeIcon } from "icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "icons/eye-off.svg";
import { Controller, useFormContext } from "react-hook-form";
import Input from "components/FormControls/Input";

const FormInput = ({
  label,
  name,
  type,
  placeholder,
  defaultValue = "",
  autoFocus,
  hint,
  className = "",
  required = false,
  maxLength,
  iconRight,
}) => {
  const { control, setValue } = useFormContext();
  const [characterCount, setCharacterCount] = React.useState(0);
  const [showPassword, setShowPassword] = React.useState(false);
  const isPasswordInput = type === "password";

  const inputId = React.useMemo(() => `input-${name}`, [name]);

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [defaultValue]);

  return (
    <>
      {label && (
        <label
          htmlFor={inputId}
          className={cn("block text-sm font-medium leading-6 text-gray-900", {
            asterisk: required,
          })}
        >
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error, invalid, isDirty } }) => {
          return (
            <div className="relative">
              <Input
                {...field}
                defaultValue={defaultValue}
                onChange={(e) => {
                  // Your character count logic here
                  const inputValue = e.target.value;
                  if (maxLength && inputValue.length > maxLength) {
                    e.target.value = inputValue.slice(0, maxLength);
                  } else {
                    field.onChange(e);
                    setCharacterCount(inputValue.length);
                  }
                }}
                className={className}
                type={
                  isPasswordInput ? (showPassword ? "text" : "password") : type
                }
                name={name}
                id={inputId}
                autoFocus={autoFocus}
                placeholder={placeholder}
                error={error}
                hint={hint}
                valid={(isDirty || field.value) && !invalid && !error?.[name]}
                iconRight={
                  isPasswordInput ? (
                    <button
                      tabIndex="-1"
                      type="button"
                      className="h-5 w-5"
                      onClick={() => setShowPassword((p) => !p)}
                    >
                      {showPassword ? <EyeOffIcon /> : <EyeIcon />}
                    </button>
                  ) : iconRight ? (
                    iconRight
                  ) : null
                }
                count={characterCount}
                maxLength={maxLength}
              />
            </div>
          );
        }}
      />
    </>
  );
};

export default FormInput;
