import React from "react";
import cn from "lib/cn";

const FormLabel = ({
  htmlFor,
  className = "",
  variant = "secondary",
  required = false,
  children,
}) => {
  const id = React.useId();

  return (
    <label
      htmlFor={htmlFor || id}
      className={cn("text-sm font-medium", className, {
        "text-text-primary": variant === "primary",
        "text-text-secondary": variant === "secondary",
        asterisk: required
      })}
    >
      {children}
    </label>
  );
};

export default FormLabel;
