import { memo, useCallback, useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Chip from "../../../../../components/Chip";
import Icon from "../../../../../components/Icon";
import FormInput from "../../../../../components/Form/Input";
import { ReactComponent as CheckIcon } from "../../../../../icons/check.svg";
import { ReactComponent as CloseIcon } from "../../../../../icons/x.svg";

const features = ["Bike suspension", "Helmet", "Disk breaks", "Good battery"];

const SelectFeatureStep = () => {
  const [selected, setSelected] = useState({});
  const [showInput, setShowInput] = useState(false);

  const onSelect = useCallback((name) => {
    setSelected((prevSelected) => ({
      ...prevSelected,
      [name]: name,
    }));
  }, []);

  const onUnselect = useCallback((name) => {
    setSelected((prevSelected) => {
      const _selected = { ...prevSelected };
      delete _selected[name];
      return _selected;
    });
  }, []);

  const addNewFeature = useCallback((feature) => {
    setSelected((p) => ({
      ...p,
      [feature]: feature,
    }));
    setShowInput(false);
  }, []);

  const selectedChips = Object.keys(selected);

  const unSelectedchips = features.filter(
    (chip) => !selectedChips.includes(chip)
  );

  return (
    <div className="max-w-2xl mx-auto">
      <div className="w-full">
        <h1 className="text-2xl text-[#344054] font-semibold mb-[10px]">
          Features
        </h1>
        <p className="text-sm text-[#344054] font-medium mb-6">
          Please select one or more than one to show what you have to offer
        </p>

        <UnSelectedArea chips={unSelectedchips} onClick={onSelect} />

        <div className="mt-6">
          <NewFeature
            show={showInput}
            setShow={setShowInput}
            onAdd={addNewFeature}
          />
        </div>

        {selectedChips.length > 0 && (
          <>
            <div className="my-6">
              <p className="text-sm text-[#344054] font-medium">Selected</p>
            </div>

            <SelectedArea chips={selectedChips} onClick={onUnselect} />
          </>
        )}
      </div>
    </div>
  );
};

// New feature
const validationSchema = yup.object({
  title: yup.string().required("Required"),
});

const NewFeature = memo(({ show, setShow, onAdd }) => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onSubmit",
    defaultValues: {
      title: "",
    },
  });

  // reset form when component unmounts
  useEffect(() => {
    return () => {
      methods.reset();
    };
  }, [show, methods]);

  const onSubmit = (data) => {
    onAdd(data.title);
  };

  return !show ? (
    <Chip title="Others" variant="default" onClick={() => setShow(true)} />
  ) : (
    <FormProvider {...methods}>
      <form noValidate className="flex gap-x-8">
        <div className="flex-1">
          <FormInput
            autoFocus
            name="title"
            type="text"
            placeholder="Enter feature"
          />
        </div>
        <div className="flex gap-x-4 mt-3">
          <CheckIcon
            className="cursor-pointer"
            // programatically submit form on click
            onClick={() => methods.handleSubmit(onSubmit)()}
          />
          <CloseIcon
            className="cursor-pointer"
            onClick={() => setShow(false)}
          />
        </div>
      </form>
    </FormProvider>
  );
});

const UnSelectedArea = memo(({ chips, onClick }) => {
  const onSelect = useCallback((name) => {
    onClick(name);
  }, []);

  return (
    <div className="flex flex-wrap gap-x-2">
      {chips.map((feat, idx) => (
        <Chip variant="default" key={idx} title={feat} onClick={onSelect} />
      ))}
    </div>
  );
});

const SelectedArea = memo(({ chips, onClick }) => {
  const onSelect = useCallback((name) => {
    onClick(name);
  }, []);

  return (
    <div className="flex flex-wrap gap-2">
      {chips.map((feat, idx) => (
        <Chip
          key={idx}
          title={feat}
          variant="success"
          icon={<Icon name="minus" size="20" fill="#067647" />}
          onClick={onSelect}
        />
      ))}
    </div>
  );
});

export default SelectFeatureStep;
