import React from "react";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInput from "../../../components/Form/Input";
import Button from "../../../components/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  loginWithEmailAndPasswordAction,
  loginWithGoogleAction,
  setAuthLoading,
} from "../../../store/slices/auth-slice";
import Toast from "../../../components/Toast/toast";
import BackButton from "../../../components/BackButton";

const validationSchema = yup.object({
  email: yup.string().required("Required").email("Invalid email"),
  password: yup
    .string()
    .required("Required")
    .min(6, "Password must be at 6 char long"),
});

const Login = () => {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);
  const googleLoading = useSelector((state) => state.auth.googleLoading);

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    // reValidateMode: "onSubmit",
    mode: "onBlur",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onGoogleLogin = () => {
    dispatch(loginWithGoogleAction())
      .unwrap()
      .then(() => {
        Toast.success("Logged in successfully!");
      })
      .catch((error) => {
        Toast.error(error.message);
      });
  };

  const onSubmit = (values) => {
    dispatch(setAuthLoading(true));
    dispatch(
      loginWithEmailAndPasswordAction({
        email: values.email,
        password: values.password,
      })
    )
      .unwrap()
      .then(() => {
        Toast.success("Logged in successfully!");
        methods.reset();
      })
      .catch((error) => {
        Toast.error(error.message);
        dispatch(setAuthLoading(false));
      });
  };
  return (
    <FormProvider {...methods}>
      <div className="mx-auto w-full max-w-sm lg:w-96">
        <div>
          <BackButton to="/" title="Back to home" className="mb-8" />
          <img
            className="h-16 w-auto"
            src="/images/logo.png"
            alt="Your Company"
          />
          <h2 className="mt-8 text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-sm leading-6 text-gray-500">
            Not a member?{" "}
            <Link
              to="/register"
              className="font-semibold text-primary hover:text-blue-600"
            >
              Start for free
            </Link>
          </p>
        </div>

        <div className="mt-10">
          <div>
            <form noValidate onSubmit={methods.handleSubmit(onSubmit)}>
              <div>
                <div className="mt-1">
                  <FormInput
                    required
                    autoFocus
                    label="Email address"
                    name="email"
                    type="email"
                    placeholder="Enter email"
                  />
                </div>
              </div>

              <div>
                <div className="mt-4">
                  <FormInput
                    required
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="Enter password"
                  />
                </div>
              </div>

              <div className="flex items-center justify-end my-4">
                <div className="text-sm leading-6">
                  <a
                    href="#"
                    className="font-semibold text-primary hover:text-blue-600"
                  >
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                <Button
                  loading={loading}
                  type="submit"
                  className="bg-primary w-full"
                >
                  Sign in
                </Button>
              </div>
            </form>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-200" />
              </div>
              <div className="relative flex justify-center text-sm font-medium leading-6">
                <span className="bg-white px-6 text-gray-900">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-4">
              <Button
                className="w-full !bg-[#24292F]"
                loading={googleLoading}
                onClick={onGoogleLogin}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-google"
                  viewBox="0 0 16 16"
                >
                  <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                </svg>
                <span className="text-sm font-semibold leading-6">Google</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Login;
