import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import createListingSchema from "./validation";
import { LISTING_ROUTES } from "./routes";
import CreateListingContext from ".";
import { DISCOUNT_LIST } from "containers/Listings/CreateListing/ListingSteps/Bikes/Discounts";
import { setValues } from "lib/react-hook-form";
import { useLocation } from "react-router";
import { getPersistedListings, persistListing } from "./utils";

export const LISTING_PATHS = LISTING_ROUTES.map((route) => route.path);

const defaultValues = {
  listing: "",
  listingType: "",
  listingEquipments: {
    brandName: "",
    model: "",
    size: "",
    seats: 1,
    gender: "unisex",
    modelYear: "",
  },
  listingFeatures: {
    categories: [],
    features: [],
  },
  rentalDetails: {
    name: "",
    pickupLocation: "",
    notes: "",
    inventory: 5,
    returnsAndCancellations: "",
  },
  photos: [],
  price: 50,
  pricingType: "per_day",
  accessories: [],
  discount: [DISCOUNT_LIST[0]?.id],
};

const CreateListingProvider = ({ children }) => {
  const persistedData = getPersistedListings();
  const methods = useForm({
    defaultValues: persistedData?.data || defaultValues,
    resolver: yupResolver(createListingSchema),
    mode: "onBlur"
  });
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);

  const formValues = methods.watch();
  // if there are stored values in localStorage, set back
  useEffect(() => {
    if (persistedData) {
      setValues(persistedData.data, methods.setValue);
    }
  }, []);

  // whenever any value changes
  useEffect(() => {
    persistListing(formValues, location.pathname, currentStep);
  }, [formValues]);

  return (
    <FormProvider {...methods}>
      <CreateListingContext.Provider
        value={{
          paths: LISTING_PATHS,
          currentStep,
          setCurrentStep,
        }}
      >
        {children}
      </CreateListingContext.Provider>
    </FormProvider>
  );
};

export default CreateListingProvider;
