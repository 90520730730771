import React from "react";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router";

function AuthLayout() {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);

  if (user) {
    return <Navigate to={location.state?.from ?? "/"} replace />;
  }
  return (
    <div className="flex min-h-full flex-1">
      <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <Outlet />
        </div>
      </div>
      <div className="relative hidden w-0 flex-1 lg:block">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src="/images/background-auth.jpg"
          alt=""
        />
      </div>
    </div>
  );
}

export default AuthLayout;
