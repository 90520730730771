import { useEffect, useCallback, useMemo, useState } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import Stays from "../screens/Stays";
import StaysCategory from "../screens/StaysCategory";
import StaysProduct from "../screens/StaysProduct";
import StaysCheckout from "../screens/StaysCheckout";
import StaysCheckoutComplete from "../screens/StaysCheckoutComplete";
import FullPhoto from "../screens/FullPhoto";
import Flights from "../screens/Flights";
import FlightsCategory from "../screens/FlightsCategory";
import ThingsToDo from "../screens/ThingsToDo";
import Category from "../screens/Category";
import Product from "../screens/Product";
import Checkout from "../screens/Checkout";
import CheckoutComplete from "../screens/CheckoutComplete";
import Cars from "../screens/Cars";
import CarsCategory from "../screens/CarsCategory";
import CarsCategory2 from "../screens/CarsCategory2";
import CarsProduct from "../screens/CarsProduct";
import CarsCheckout from "../screens/CarsCheckout";
import CarsCheckoutComplete from "../screens/CarsCheckoutComplete";
import HostProfile from "../screens/HostProfile";
import ProfileUser from "../screens/ProfileUser";
import AccountSettings from "../screens/AccountSettings";
import Support from "../screens/Support";
import PageList from "../screens/PageList";
import MessageCenter from "../screens/MessageCenter";
import Wishlists from "../screens/Wishlists";
import YourTrips from "../screens/YourTrips";
import Bookings from "../screens/Bookings";
import ListYourProperty from "../screens/ListYourProperty";
import CustomerLayout from "../layout/CustomerLayout";
import Login from "../screens/Auth/Login";
import Register from "../screens/Auth/Register";
import { useDispatch, useSelector } from "react-redux";
import { auth } from "../lib/firebase";
import {
  getUserByEmail,
  signInWithOneTapGoogle,
} from "../services/auth-service";
import {
  setAuthLoading,
  setGlobalUser,
  setGoogleLoading,
} from "../store/slices/auth-slice";
import useGoogleTapSignIn from "../hooks/useGoogleTapSignin";
import Toast from "../components/Toast/toast";
import AuthLayout from "../layout/AuthLayout";
import VendorLayout from "../layout/VendorLayout";
import ViewListing from "../screens/Listings/View";
import EditListing from "../screens/Listings/Edit";
import ListListings from "../screens/Listings/List";
import CreateListing from "containers/Listings/CreateListing";
// import ListingTypeStep from "../screens/Onboardings/Vendor/Steps/ListingTypeStep";
import SelectBrandStep from "../screens/Onboardings/Vendor/Steps/SelectBrandStep";
import SelectFeatureStep from "../screens/Onboardings/Vendor/Steps/SelectFeatureStep";
import AppLoader from "../components/AppLoader";
import VendorSetup from "../screens/Vendor/Setup";
import PrivateLayout from "../layout/PrivateLayout";
import { LISTING_ROUTES } from "contexts/create-listing/routes";
import ListingLayout from "layout/ListingLayout";
import SeoLayout from "layout/SeoLayout";
import ListingSuccess from "containers/Listings/CreateListing/Success";

const AppRouter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [appLoading, setAppLoading] = useState(true);

  const callback = useCallback(
    async (credentials) => {
      if (credentials) {
        dispatch(setGoogleLoading(true));
        signInWithOneTapGoogle(credentials)
          .then(() => {
            Toast.success("Logged in successfully!");
            navigate("/");
          })
          .catch((error) => {
            dispatch(setGoogleLoading(false));
            Toast.error(
              error.message ||
                error.response?.data?.message ||
                "Failed to signin"
            );
          });
      }
    },
    [dispatch, navigate]
  );

  const activateOnRoutes = useMemo(() => ["/login", "/register"], []);

  useGoogleTapSignIn({
    redirect: false,
    parentContainerId: "oneTap",
    activateOnRoutes,
    callback,
    loading: appLoading,
  });

  useEffect(() => {
    // dispatch(setAuthLoading(true));
    // dispatch(setGoogleLoading(true));
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const dbUser = await getUserByEmail(user.email);
        dispatch(setGlobalUser(dbUser));
      }
      // dispatch(setAuthLoading(false));
      // dispatch(setGoogleLoading(false));
      setAppLoading(false);
    });
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (appLoading) {
    return <AppLoader />;
  }

  return (
    <>
      <div id="oneTap" className="fixed top-0 right-0 z-50" />
      <Routes>
        <Route element={<SeoLayout />}>
          <Route element={<CustomerLayout />}>
            <Route path="/" element={<Stays />} />
            <Route path="/stays-category" element={<StaysCategory />} />
            <Route path="/stays-product" element={<StaysProduct />} />
            <Route path="/stays-checkout" element={<StaysCheckout />} />
            <Route
              path="/stays-checkout-complete"
              element={<StaysCheckoutComplete />}
            />
            <Route path="/full-photo" element={<FullPhoto />} />
            <Route path="/flights" element={<Flights />} />
            <Route path="/flights-category" element={<FlightsCategory />} />
            <Route path="/things-to-do" element={<ThingsToDo />} />
            <Route path="/category" element={<Category />} />
            <Route path="/product" element={<Product />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/checkout-complete" element={<CheckoutComplete />} />
            <Route path="/cars" element={<Cars />} />
            <Route path="/cars-category" element={<CarsCategory />} />
            <Route path="/cars-category-2" element={<CarsCategory2 />} />
            <Route path="/cars-product" element={<CarsProduct />} />
            <Route path="/cars-checkout" element={<CarsCheckout />} />
            <Route
              path="/cars-checkout-complete"
              element={<CarsCheckoutComplete />}
            />
            <Route path="/messages" element={<MessageCenter />} />
            <Route path="/wishlists" element={<Wishlists />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/your-trips" element={<YourTrips />} />
            <Route path="/profile" element={<ProfileUser />} />
            <Route path="/host-profile" element={<HostProfile />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/support" element={<Support />} />
          </Route>
          <Route element={<AuthLayout />}>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
          </Route>
          {/* <Route path="/vendor/setup" element={<VendorSetupLayout />}>
          <Route path="step1" element={<ListingTypeStep />} />
          <Route path="step2" element={<SelectBrandStep />} />
          <Route path="step3" element={<SelectFeatureStep />} />
        </Route> */}
          <Route element={<PrivateLayout />}>
            <Route path="/become-a-seller" element={<CreateListing />} />
            <Route element={<ListingLayout />}>
              {LISTING_ROUTES.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  element={route.element}
                />
              ))}
              <Route
                key="become-a-seller/:listingId/:typeId/success"
                path="become-a-seller/:listingId/:typeId/success"
                element={<ListingSuccess />}
              />
            </Route>
            <Route path="/vendor" element={<VendorLayout />}>
              {/* <Route index element={<Navigate to="/vendor/setup" />} /> */}
              {/* <Route path="setup" element={<VendorSetup />} /> */}
              <Route path="listings" element={<ListListings />} />
              {/* <Route path="create-listing" element={<CreateListing />} /> */}
              {/* <Route path="view-listing/:id" element={<ViewListing />} /> */}
              {/* <Route path="edit-listing/:id" element={<EditListing />} /> */}
              {/* <Route path="list-your-property" element={<ListYourProperty />} /> */}
            </Route>
          </Route>

          <Route path="/pagelist" component={PageList} />
        </Route>
      </Routes>
    </>
  );
};

export default AppRouter;
