import React, { useMemo, useState } from "react";
import cn from "lib/cn";
import styles from "./Header.module.sass";
import { Link, NavLink } from "react-router-dom";
import Image from "../Image";
import Dropdown from "./Dropdown";
import Language from "./Language";
import Notification from "./Notification";
import User from "./User";
import Modal from "../Modal";
import Login from "../Login";
import NotAuthorizedButtons from "./NotAuthorizedButtons";
import { useSelector } from "react-redux";

const travelers = [
  {
    title: "Stays",
    url: "/",
    icon: "comment",
  },
  {
    title: "Flights",
    url: "/flights",
    icon: "email",
  },
  {
    title: "Things to do",
    url: "/things-to-do",
    icon: "home",
  },
  {
    title: "Cars",
    url: "/cars",
    icon: "email",
  },
];

const languages = [
  {
    title: "English",
    country: "United States",
    url: "/",
  },
  {
    title: "Vietnamese",
    country: "Vietnamese",
    url: "/",
  },
  {
    title: "Français",
    country: "Belgique",
    url: "/",
  },
  {
    title: "Français",
    country: "Canada",
    url: "/",
  },
];

const customerItems = [
  {
    menu: [
      {
        title: "Messages",
        icon: "comment",
        url: "/messages",
      },
      {
        title: "Bookings",
        icon: "home",
        url: "/bookings",
      },
      {
        title: "Wishlists",
        icon: "email",
        url: "/wishlists",
      },
    ],
  },
  {
    menu: [
      {
        title: "Switch to provide",
        icon: "flag",
        url: "/vendor/listings",
      },
    ],
  },
];

const vendorItems = [
  {
    menu: [
      {
        title: "Messages",
        icon: "comment",
        url: "/messages",
      },
      {
        title: "Bookings",
        icon: "home",
        url: "/bookings",
      },
      {
        title: "Wishlists",
        icon: "email",
        url: "/wishlists",
      },
    ],
  },
  {
    menu: [
      {
        title: "Create listing",
        icon: "building",
        url: "/vendor/list-your-property",
        // url: "/become-a-seller/:listingId/select-listing",
      },
      {
        title: "Switch to experience",
        icon: "flag",
        url: "/",
      },
    ],
  },
];

const Header = ({
  type = "customer",
  separatorHeader,
  wide,
  notAuthorized,
}) => {
  const [visibleNav, setVisibleNav] = useState(false);
  const [visible, setVisible] = useState(false);

  const loading = useSelector((state) => state.auth.loading);

  const items = useMemo(() => {
    if (type === "customer") {
      return customerItems;
    }
    return vendorItems;
  }, [type]);

  return (
    <>
      <div
        className={cn(
          styles.header,
          { [styles.headerBorder]: separatorHeader },
          { [styles.wide]: wide }
        )}
      >
        <div className={cn("container", styles.container)}>
          <Link className={cn(styles.logo, "h-8")} to="/">
            <Image
              src="/images/logo.png"
              srcDark="/images/logo.png"
              alt="Fleet"
            />
          </Link>
          <div className={cn(styles.wrapper, { [styles.active]: visibleNav })}>
            {/* <Dropdown
              className={styles.drowdown}
              items={travelers}
              setValue={setVisibleNav}
            /> */}
            {/* <NavLink
              to="/support"
              className={({ isActive }) => {
                return cn(styles.link, {
                  [styles.active]: isActive,
                });
              }}
            >
              Support
            </NavLink> */}
            {false && (
              <Language className={styles.language} items={languages} />
            )}
          </div>
          {/* Logged in */}
          {!notAuthorized && (
            <>
              {type === "vendor" && (
                <NavLink
                  className={({ isActive }) => {
                    return cn("button-stroke button-small", styles.button, {
                      [styles.active]: isActive,
                    });
                  }}
                  to="/become-a-seller"
                >
                  Create listing
                </NavLink>
              )}
              <Notification className={styles.notification} />
            </>
          )}
          {/* Not Logged in */}
          {notAuthorized ? (
            <div className="ml-8">
              <NotAuthorizedButtons loading={loading} />
            </div>
          ) : (
            <User type={type} className={styles.user} items={items} />
          )}
          <button
            className={cn(styles.burger, { [styles.active]: visibleNav })}
            onClick={() => setVisibleNav(!visibleNav)}
          ></button>
        </div>
      </div>
      <Modal visible={visible} onClose={() => setVisible(false)}>
        <Login />
      </Modal>
    </>
  );
};

export default Header;
