import React from "react";
import { Link } from "react-router-dom";
import Button from "../../Button";
import LinkButton from "../../LinkButton";

const NotAuthorizedButtons = ({ loading }) => {
  return (
    <div className="flex items-center gap-x-4">
      <LinkButton loading={loading} to="/login">
        Log in
      </LinkButton>
      <LinkButton loading={loading} to="/register" variant="primary">
        Start free now
      </LinkButton>
    </div>
  );
};

export default NotAuthorizedButtons;
