import React from "react";
import cn from "classnames";
import styles from "./ListingCard.module.sass";

const ListingCard = ({
  className,
  type,
  title,
  description,
  image,
  selected,
  onSelect,
}) => {
  return (
    <div
      className={cn(className, styles.card, "cursor-pointer shadow", {
        "!border-primary !border-2 !border-solid": selected,
      })}
      onClick={() => onSelect?.(type)}
    >
      <div className={styles.preview}>
        <img src={image} alt="Card" />
      </div>
      {(title || description) && (
        <div className={styles.body}>
          <div className={styles.line}>
            <div className={styles.details}>
              <div className={cn(styles.subtitle, "mb-2")}>{title}</div>
              <div className={styles.description}>{description}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default React.memo(ListingCard);
