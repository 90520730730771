import React, { useEffect } from "react";
import { useParams } from "react-router";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import getBikesBreadcrumb from "../breadcrumbs";
import { useCreateListingContext } from "contexts/create-listing";
import HintText from "components/HintText";
import UploadedFile from "components/FileUploader/UploadedFile";
import File from "components/Form/File";
import { createListingFormKeys } from "contexts/create-listing/validation";
import { useFormContext } from "react-hook-form";

const STEP_NUMBER = 6;

const EquipmentPhotos = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const { watch, setValue } = useFormContext();

  const uploadedPhotos = watch(createListingFormKeys.photos).filter(
    (f) => f.size
  );
  const uploadedPhotosCount = uploadedPhotos.length;

  const onRemoveFile = (index) => {
    setValue(
      createListingFormKeys.photos,
      uploadedPhotos.filter((_, idx) => index !== idx)
    );
  };

  return (
    <ListingPage
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={uploadedPhotosCount < 2}
          nextUrl={`/become-a-seller/12345/${params.typeId}/price`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto mt-10 md:mt-0">
        <div className="flex flex-col gap-y-6">
          <p className="text-sm font-medium text-text-secondary asterisk">
            Upload the photos of the bike
          </p>
          <div className="md:flex gap-16 w-full">
            <File
              name={createListingFormKeys.photos}
              accept={{
                "image/*": [".jpeg", ".png", ".svg", ".gif"],
              }}
            />
          </div>

          {/* Files */}
          <div className="flex flex-col gap-y-3 max-h-[225px] overflow-y-auto">
            {uploadedPhotos.map((photo, index) => (
              <UploadedFile
                key={`uploaded_photo__${index}`}
                name={photo.name}
                size={photo.size}
                type={photo.type}
                onRemove={() => onRemoveFile(index)}
              />
            ))}
          </div>

          <div>
            <HintText>
              Minimum two photos required{" "}
              <a className="font-semibold text-text-link">Learn More</a>
            </HintText>
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default EquipmentPhotos;
