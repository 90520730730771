import LocalStorage from "utils/localStorage";

export const getPersistedListings = () => {
  return LocalStorage.getLocalStorage("@ghr/create_listing");
};

export const persistListing = (data, url, currentStep, status) => {
  LocalStorage.setLocalStorage("@ghr/create_listing", {
    currentStep,
    url,
    data,
    status,
  });
};

export const clearPersistedListings = () => {
  LocalStorage.removeLocalStorage("@ghr/create_listing");
};
