import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./ReviewListingCard.module.sass";

const ReviewListing = ({
  className,
  url,
  title,
  brand,
  category,
  categoryText,
  image,
  imageClass = "",
  priceOld,
  priceActual,
  gender,
  row,
  car,
}) => {
  return (
    <Link
      className={cn(
        className,
        styles.card,
        { [styles.row]: row },
        { [styles.car]: car }
      )}
      to={url}
    >
      <div className={styles.preview}>
        <img className={imageClass} src={image} alt="Nature" />
        {categoryText && (
          <div
            className={cn(
              "category",
              { "category-blue": category === "blue" },
              styles.category
            )}
          >
            {categoryText}
          </div>
        )}
      </div>
      <div className={styles.body}>
        <div className={cn(styles.line, "!mb-[10px]")}>
          <div className="flex flex-col">
            <div className={cn(styles.title, "!text-lg !font-medium")}>
              {title}
            </div>
            {brand && (
              <div className="font-normal text-sm text-text-secondary">
                {brand}
              </div>
            )}
          </div>
          <div className={styles.price}>
            <div className={styles.old}>{priceOld}</div>
            <div className={styles.actual}>{priceActual}</div>
          </div>
        </div>
        <div className={cn(styles.foot, "!pt-[10px]")}>
          <div className="flex justify-between items-center">
            <div className="text-sm font-medium text-[#475467]">{gender}</div>
            <div className="text-sm font-medium text-[#475467]">
              <span>Large</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ReviewListing;
