import { SnackbarProvider } from "notistack";

const ToastProvider = ({ children }) => {
  return (
    <SnackbarProvider
      dense
      maxSnack={1}
      preventDuplicate
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default ToastProvider;
