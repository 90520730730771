import React, { useEffect } from "react";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import { useParams } from "react-router";
import getBikesBreadcrumb from "../breadcrumbs";

const STEP_NUMBER = 9;

const Availability = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  return (
    <ListingPage
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={false}
          nextUrl={`/become-a-seller/12345/${params.typeId}/review`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto mt-10 md:mt-0 max-w-[600px]">
        <div>
          <h1 className="text-2xl font-bold text-[#344054] mb-[10px]">
            Select the available day and time
          </h1>
          <p className="text-sm font-medium text-[#344054]">
            Please select one or more day to show your availability
          </p>
        </div>
      </div>
    </ListingPage>
  );
};

export default Availability;
