import React, { useEffect } from "react";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import { useParams, useNavigate, generatePath } from "react-router";
import FormLabel from "components/Form/Label";
import ReviewListing from "components/Cards/ReviewListing";
import Badge from "components/Badge";
import { ALL_BIKE_STEPS, getPathByStep } from "../steps";
import { createSearchParams } from "react-router-dom";
import useScrollPosition from "hooks/useScrollPosition";
import { useFormContext } from "react-hook-form";
import { DISCOUNT_LIST } from "../Discounts";
import { LISTINGS } from "../../../SelectListing";
import { BIKES } from "..";
import getBikesBreadcrumb from "../breadcrumbs";
import { GENDERS } from "../EquipmentDetails";

const STEP_NUMBER = 10;

const Review = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  // for preserving the scroll when clicked on change and come back to same page
  useScrollPosition("review_page", document.getElementById("listing-scroller"));

  const params = useParams();
  const navigate = useNavigate();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const jumpToStep = (step) => {
    const unresolvedPath = getPathByStep(step);
    const path = generatePath(unresolvedPath, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
    navigate({
      pathname: path,
      search: `?${createSearchParams({
        mode: "review",
      })}`,
    });
  };

  const { getValues } = useFormContext();

  const formValues = getValues();
  const discounts = DISCOUNT_LIST.filter((d) =>
    formValues.discount.includes(d.id)
  );
  const listing = LISTINGS.find((l) => l.id === formValues?.listing);
  const listingType = BIKES.find(
    (listingType) => listingType.id === formValues?.listingType
  );
  const gender = GENDERS.find(
    (gender) => gender.id === formValues.listingEquipments?.gender
  );

  return (
    <ListingPage
      containerClass="!h-auto"
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={false}
          nextUrl={`/become-a-seller/12345/${params.typeId}/success`}
        />
      }
    >
      <div className="w-full mx-auto mt-20 md:mt-0">
        <div className="block md:flex justify-between gap-x-[96px]">
          <div className="flex flex-col gap-y-6 w-full mb-4 md:fixed md:w-[300px] md:mb-0">
            <h1 className="text-4xl font-bold text-[#101828]">Review</h1>
            <ReviewListing
              title={listing?.title}
              brand={formValues?.listingEquipments?.brandName}
              priceOld={`$ ${formValues.price}`}
              priceActual={`$ ${formValues.price}`}
              image={listing?.image}
              gender={gender?.value}
            />
          </div>
          <div className="ml-0 md:ml-[340px] lg:ml-[396px] flex flex-col flex-1">
            <ReviewItem
              label="Name"
              value={listingType?.title}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.ListingType.step)}
            />
            <ReviewItem
              label="Brand"
              value={formValues.listingEquipments.brandName}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Model"
              value={formValues.listingEquipments.model}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Size"
              value={formValues.listingEquipments.size}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Seats"
              value={formValues.listingEquipments.seats}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Gender"
              value={gender?.value}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Model year"
              value={formValues.listingEquipments.modelYear}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.BasicDetails.step)}
            />
            <ReviewItem
              label="Categories"
              onClick={() =>
                jumpToStep(ALL_BIKE_STEPS.CategoriesAndFeatures.step)
              }
            >
              {formValues.listingFeatures?.categories?.map((cat, i) => (
                <Badge key={`category__${i}`} selected className="cursor-auto">
                  {cat}
                </Badge>
              ))}
            </ReviewItem>

            <ReviewItem
              label="Features"
              onClick={() =>
                jumpToStep(ALL_BIKE_STEPS.CategoriesAndFeatures.step)
              }
            >
              {formValues.listingFeatures?.features?.map((feature, index) => (
                <Badge
                  key={`feature__${index}`}
                  selected
                  className="cursor-auto"
                >
                  {feature}
                </Badge>
              ))}
            </ReviewItem>

            <ReviewItem
              label="Price"
              value={`$ ${formValues.price}`}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.Price.step)}
            />

            <ReviewItem
              label="Discount"
              onClick={() => jumpToStep(ALL_BIKE_STEPS.Discount.step)}
            >
              <div className="flex flex-col">
                {discounts.map((discount) => (
                  <FormLabel
                    key={discount.id}
                    variant="primary"
                    className="!text-base !flex-1"
                  >
                    {`${discount?.percentage}% ${discount?.title} ${discount?.description}`}
                  </FormLabel>
                ))}
              </div>
            </ReviewItem>
            <ReviewItem
              label="Pickup location"
              onClick={() => jumpToStep(ALL_BIKE_STEPS.RentalDetails.step)}
            >
              <a
                className="font-medium text-base"
                href={formValues.rentalDetails?.pickupLocation}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formValues.rentalDetails?.pickupLocation}
              </a>
            </ReviewItem>
            <ReviewItem
              label="Notes"
              value={formValues.rentalDetails?.notes}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.RentalDetails.step)}
            />
            <ReviewItem
              label="Inventory"
              value={`${formValues.rentalDetails?.inventory} units`}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.RentalDetails.step)}
            />
            <ReviewItem
              label="Returns and cancellations"
              value={formValues.rentalDetails?.returnsAndCancellations}
              onClick={() => jumpToStep(ALL_BIKE_STEPS.RentalDetails.step)}
            />

            <ReviewItem label="Availabile days and time">---</ReviewItem>
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default Review;

const ReviewItem = ({ label, value, children, onClick }) => {
  return (
    <div className="flex flex-col gap-y-[6px] p-3 w-full">
      <FormLabel>{label}</FormLabel>
      <div className="flex justify-between gap-x-2">
        {children ? (
          <div className="flex gap-x-[10px] gap-y-2 items-center flex-wrap">
            {children}
          </div>
        ) : (
          <FormLabel variant="primary" className="!text-base !flex-1">
            {value}
          </FormLabel>
        )}
        <button className="font-bold text-text-link" onClick={onClick}>
          Change
        </button>
      </div>
    </div>
  );
};
