import { enqueueSnackbar } from "notistack";

const success = (message, options = {}) => {
  enqueueSnackbar(message, {
    variant: "success",
    autoHideDuration: 3000,
    ...options,
  });
};

const info = (message, options = {}) => {
  enqueueSnackbar(message, {
    variant: "info",
    autoHideDuration: 3000,
    ...options,
  });
};

const error = (message, options = {}) => {
  enqueueSnackbar(message, {
    variant: "error",
    autoHideDuration: 3000,
    ...options,
  });
};

const warning = (message, options = {}) => {
  enqueueSnackbar(message, {
    variant: "warning",
    autoHideDuration: 3000,
    ...options,
  });
};

const unstyled = (message, options = {}) => {
  enqueueSnackbar(message, {
    variant: "default",
    autoHideDuration: 3000,
    ...options,
  });
};

const Toast = { success, info, error, warning, unstyled };

export default Toast;
