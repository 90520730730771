import React from "react";
import { ReactComponent as FileIcon } from "icons/file.svg";

const FileType = ({ extension = "jpg" }) => {
  return (
    <div className="relative">
      <FileIcon />
      <p className="absolute bottom-[6px] text-[10px] ml-[-6px] bg-primary text-white px-[3px] py-[2px] rounded-[4px] font-bold uppercase">
        {extension}
      </p>
    </div>
  );
};

export default FileType;
