import React from "react";
import { Outlet, useLocation } from "react-router";
import Page from "../components/Page";
import { useSelector } from "react-redux";

const footerHideRoutes = ["/messages"];
const wideRoutes = ["/messages"];

const VendorLayout = () => {
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();
  // const separatorHeader = separatorHeaderRoutes.includes(location.pathname);
  const footerHide = footerHideRoutes.includes(location.pathname);
  const wide = wideRoutes.includes(location.pathname);

  return (
    <Page
      type="vendor"
      notAuthorized={!Boolean(user)}
      footerHide={footerHide}
      separatorHeader
      // wide={wide}
      wide={false}
      // wide
    >
      <div className="section">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </Page>
  );
};
export default VendorLayout;
