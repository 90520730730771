import React, { useId } from "react";
import cn from "lib/cn";
import { Tooltip } from "react-tooltip";

const IconButton = ({
  className = "",
  children,
  onClick,
  tooltip,
  variant = "default", // primary, default
  tooltipPlacement = "top",
  ...rest
}) => {
  const id = useId();
  return (
    <div>
      <Tooltip id={id} place={tooltipPlacement}>
        {tooltip}
      </Tooltip>
      <button
        data-tooltip-id={id}
        className={cn(
          "text-navy-700 rounded-full p-3 text-base font-medium transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30",
          {
            "bg-gray-100": variant === "primary",
            "bg-transparent": variant === "default",
          },
          className
        )}
        onClick={onClick}
        {...rest}
      >
        {children}
      </button>
    </div>
  );
};

export default IconButton;
