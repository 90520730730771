import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import AppLoader from "components/AppLoader";
import { createDraftListing } from "services/listing-service";

const CreateListing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const kickOffListing = async () => {
      const listing = await createDraftListing();
      navigate(`/become-a-seller/${listing.id}/select-listing`, {
        replace: true,
      });
    };

    kickOffListing();
  }, []);

  return <AppLoader />;
};

export default CreateListing;
