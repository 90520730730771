import { useCallback } from "react";
import { useNavigate } from "react-router";

const useGoBack = () => {
  const navigate = useNavigate();

  const goBack = useCallback(
    (backSteps = 1) => {
      navigate(-1);
    },
    [navigate]
  );
  return goBack;
};

export default useGoBack;
