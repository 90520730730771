import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import Select from "components/Form/Select";
import Divider from "components/Divider";
import FormLabel from "components/Form/Label";
import Badge from "components/Badge";
import HintText from "components/HintText";
import Counter from "components/Counter";
import { useCreateListingContext } from "contexts/create-listing";
import Icon from "components/Icon";
import { useFormContext } from "react-hook-form";
import FormInput from "components/Form/Input";
import { createListingFormKeys } from "contexts/create-listing/validation";
import getBikesBreadcrumb from "../breadcrumbs";

const STEP_NUMBER = 3;
const SIZES = [
  "17.5 inch",
  "Large",
  "Medium/Large",
  "Medium",
  "Small",
  "X-Large",
  "X-Small",
  "XX-Small",
  "XX-Large",
];

const MODEL_YEARS = [...new Array(10)].map((_, index) => {
  const data = new Date().getFullYear() - index;
  return {
    label: data,
    value: data,
  };
});

export const GENDERS = [
  {
    id: "unisex",
    value: "Unisex",
  },
  {
    id: "not_designated",
    value: "Not designated",
  },
];

const BikeEquipmentDetails = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();
  const [allModelYears, setAllModelYears] = useState(MODEL_YEARS.slice(0, 4));
  const [otherModelSelection, setOtherModelSelection] = useState(false);

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const modelYearsValues = allModelYears.map((y) => y.value);

  const {
    setValue,
    watch,
    formState: { defaultValues },
  } = useFormContext();
  // const brandName = watch(createListingFormKeys.listingEquipments.brandName);
  const selectedSize = watch(createListingFormKeys.listingEquipments.size);
  const selectedSeat =
    watch(createListingFormKeys.listingEquipments.seats) ||
    defaultValues.listingEquipments.seats;
  const selectedGender =
    watch(createListingFormKeys.listingEquipments.gender) ||
    defaultValues.listingEquipments.gender;
  const selectedModelYear = watch(
    createListingFormKeys.listingEquipments.modelYear
  );

  const isValid =
    // brandName &&
    selectedSize && selectedSeat && selectedModelYear;

  return (
    <ListingPage
      containerClass="!h-auto"
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={!isValid}
          nextUrl={`/become-a-seller/12345/${params.typeId}/equipment-features`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full h-full mx-auto">
        <div className="md:flex gap-16">
          <div className="flex flex-col items-start mt-5 md:mt-0">
            <h1 className="text-2xl md:text-3xl font-semibold mb-5">
              Share some basics about your bike
            </h1>
            <p className="text-sm md:text-base font-medium text-[#667085]">
              You can start with some basic details like brand, size etc. You
              can always add more details later
            </p>
            <div className="w-full mt-6">
              <FormLabel required>Brand name</FormLabel>
              <Select
                placeholder="Search brand or add yours"
                name="select"
                className="w-full"
                autoFocus
              />
              <HintText className="mt-1">
                Start typing to see existing brands, you can also add a new
                brand.
              </HintText>
            </div>

            <Divider containerClass="my-6" />

            <div className="w-full">
              <FormInput
                name={createListingFormKeys.listingEquipments.model}
                label="Model (Optional)"
                hint="Enter your bike's model"
                placeholder="Narco Velence 105 Hydro Disc"
              />
            </div>

            <Divider containerClass="my-6" />

            <div className="mb-[10px]">
              <FormLabel required>Size</FormLabel>
              <div className="flex flex-wrap gap-[10px] my-[10px]">
                {SIZES.map((size, i) => (
                  <Badge
                    selected={selectedSize === size}
                    key={`size__${i}`}
                    onClick={() =>
                      setValue(
                        createListingFormKeys.listingEquipments.size,
                        size
                      )
                    }
                  >
                    {size}
                  </Badge>
                ))}
              </div>
            </div>

            <div>
              <HintText>
                Select your bike size{" "}
                <a className="font-semibold text-text-link">Learn More</a>
              </HintText>
            </div>

            <Divider containerClass="my-6" />

            <div className="w-full">
              <div className="flex items-center justify-between w-full mb-[10px]">
                <FormLabel required>Seats</FormLabel>
                <div>
                  <Counter
                    min={1}
                    max={4}
                    value={selectedSeat}
                    setValue={(newSeat) => {
                      setValue(
                        createListingFormKeys.listingEquipments.seats,
                        newSeat
                      );
                    }}
                    iconMinus="minus"
                    iconPlus="plus"
                    size="medium"
                  />
                </div>
              </div>

              <HintText>
                How many people can it seat?{" "}
                <a className="font-semibold text-text-link" href="/help">
                  Learn More
                </a>
              </HintText>
            </div>

            <Divider containerClass="my-6" />

            <div>
              <FormLabel required>Gender</FormLabel>
              <div className="flex flex-wrap gap-[10px] my-[10px]">
                {GENDERS.map((gender, i) => (
                  <Badge
                    selected={selectedGender === gender.id}
                    key={`gender__${i}`}
                    onClick={() =>
                      setValue(
                        createListingFormKeys.listingEquipments.gender,
                        gender.id
                      )
                    }
                  >
                    {gender.value}
                  </Badge>
                ))}
              </div>
            </div>

            <Divider containerClass="my-6" />

            <div className="w-full mb-[10px]">
              <FormLabel required>Model year</FormLabel>
              <div className="flex flex-wrap gap-[10px] my-[10px]">
                {allModelYears.map((year, i) => (
                  <Badge
                    key={`year__${i}`}
                    selected={selectedModelYear === year.value}
                    onClick={() =>
                      setValue(
                        createListingFormKeys.listingEquipments.modelYear,
                        selectedModelYear === year.value ? "" : year.value
                      )
                    }
                  >
                    {year.label}
                  </Badge>
                ))}

                <Badge
                  rightIcon={
                    <Icon
                      className="ml-[2px]"
                      name="plus"
                      size="20"
                      fill="#374151"
                    />
                  }
                  onClick={() => setOtherModelSelection((p) => !p)}
                >
                  Other
                </Badge>
              </div>
              {/* Select other year dropdown */}
              {otherModelSelection && (
                <div className="my-4">
                  <Select
                    autoFocus
                    openMenuOnFocus
                    menuPlacement="top"
                    isSearchable
                    isClearable
                    placeholder="Select model year"
                    name="modelYear"
                    className="w-full"
                    options={MODEL_YEARS}
                    value={selectedModelYear}
                    onChange={({ value }) => {
                      if (!modelYearsValues.includes(value)) {
                        setAllModelYears((p) =>
                          p.includes(value)
                            ? p.filter((year) => year !== value)
                            : [
                                {
                                  label: value,
                                  value,
                                },
                                ...p,
                              ]
                        );
                      }
                      setValue(
                        createListingFormKeys.listingEquipments.modelYear,
                        value
                      );
                      setOtherModelSelection(false);
                    }}
                  />
                </div>
              )}
            </div>

            <HintText>
              Select the year of the bike{" "}
              <a className="font-semibold text-text-link">Learn More</a>
            </HintText>
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default BikeEquipmentDetails;
