import { combineReducers } from "redux";
import authReducer from "./slices/auth-slice";

// Root reducer combining multiple reducers
const combineReducer = combineReducers({
  auth: authReducer,
});
const rootReducer = (state, action) => {
  if (action.type === "reset") {
    state = undefined;
  }
  return combineReducer(state, action);
};

export default rootReducer;
