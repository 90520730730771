import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  signInWithCredentials,
  signInWithGoogle,
  logout,
  signUpWithCredentials,
} from "../../services/auth-service";
import { firebaseErrorParser } from "../../lib/firebase/error-parser";
import { appConfig } from "app-config";

export const loginWithGoogleAction = createAsyncThunk(
  "auth/loginWithGoogle",
  async (data, thunkAPI) => {
    try {
      const response = await signInWithGoogle();
      return response.user;
    } catch (error) {
      console.log(error.code, "error is here");
      return thunkAPI.rejectWithValue({
        message: firebaseErrorParser(error.code),
      });
    }
  }
);

export const loginWithEmailAndPasswordAction = createAsyncThunk(
  "auth/loginWithEmailAndPassword",
  async (data, thunkAPI) => {
    try {
      const response = await signInWithCredentials(data.email, data.password);
      return response;
    } catch (error) {
      console.log(error.code, "error is here");
      return thunkAPI.rejectWithValue({
        message: firebaseErrorParser(error.code),
      });
    }
  }
);

export const registerWithCredentialsAction = createAsyncThunk(
  "auth/registerWithCredentialsAction",
  async (data, thunkAPI) => {
    try {
      const response = await signUpWithCredentials(data.name, data.email, data.password);
      // logout the user immediately, to prevent automatically sign in after register user
      if (!appConfig.directLogin) {
        thunkAPI.dispatch(logoutAction());
      } else {
        return response;
      }
    } catch (error) {
      console.log(error.code, "error is here");
      return thunkAPI.rejectWithValue({
        message: firebaseErrorParser(error.code),
      });
    }
  }
);

export const logoutAction = createAsyncThunk(
  "auth/logout",
  async (_, thunkAPI) => {
    try {
      await logout();
      thunkAPI.dispatch({ type: "reset" });
      return {};
    } catch (error) {
      console.log(error, "errors are here!");
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    loading: false,
    googleLoading: false,
  },
  reducers: {
    setGlobalUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthLoading: (state, action) => {
      state.loading = action.payload;
    },
    setGoogleLoading: (state, action) => {
      state.loading = action.payload;
    },
    reset: (state) => {
      state.loading = false;
      state.googleLoading = false;
      state.user = null;
    },
  },
  extraReducers: (builders) => {
    /** Google Login **/
    builders.addCase(loginWithGoogleAction.fulfilled, (state, action) => {
      state.googleLoading = false;
      state.user = action.payload;
    });
    builders.addCase(loginWithGoogleAction.rejected, (state, action) => {
      state.googleLoading = false;
    });
    builders.addCase(loginWithGoogleAction.pending, (state) => {
      state.googleLoading = true;
    });

    // Register with email and password
    builders.addCase(registerWithCredentialsAction.fulfilled, (state, action) => {
      state.googleLoading = false;
      state.loading = false;
      state.user = action.payload;
    });
    builders.addCase(registerWithCredentialsAction.rejected, (state, action) => {
      state.loading = false;
    });
    builders.addCase(registerWithCredentialsAction.pending, (state) => {
      state.loading = true;
    });
    
  },
});

export const {
  reset,
  setAuth,
  setGlobalUser,
  setWalletId,
  setAuthLoading,
  setGoogleLoading,
} = authSlice.actions;

export default authSlice.reducer;
