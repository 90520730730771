export const interpolate = (
  stringToReplace,
  context,
  callback = null,
  regExp = /{(.*?)}/g
) =>
  stringToReplace.replace(regExp, (_, group) =>
    group
      .trim()
      .split(/[.[\]]/)
      .reduce(
        (prevState, contextKey) => [
          ...prevState,
          ...(contextKey ? [contextKey] : []),
        ],
        []
      )
      .reduce((prevState, contextKey, index) => {
        if (callback && typeof callback === "function") {
          return callback(prevState, contextKey, index, context);
        }

        return prevState[contextKey] || "";
      }, context)
  );

export function getUnresolvedPath(pathname, context) {
  Object.keys(context).forEach((key) => {
    const value = context[key];
    const regex = new RegExp(`\\b${key}\\b`, "g");
    pathname = pathname.replace(regex, `:${value}`);
  });

  return pathname;
}

