import React from "react";
import cn from "classnames";

const Divider = ({
  height = 1,
  borderColor = "#EAECF0",
  containerClass = "",
}) => {
  return (
    <div className={cn("relative w-full my-2", containerClass)}>
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div
          className="w-full border-solid"
          style={{
            borderTopWidth: height,
            borderColor,
          }}
        />
      </div>
    </div>
  );
};

export default Divider;
