import Checkbox from "components/FormControls/Checkbox";
import React from "react";
import { useFormContext } from "react-hook-form";

export const CUSTOM_RETURNS_AND_CANCELLATIONS_PRESETS = [
  "In case of cancellation only 80% will be refunded",
  "100% will be refunded if canceled within 1 hour of order",
  "Non refundable",
];

const CustomReturnAndCancellation = ({
  name,
  selected,
  isCustom,
  setIsCustom,
}) => {
  const { setValue } = useFormContext();
  return (
    <>
      {CUSTOM_RETURNS_AND_CANCELLATIONS_PRESETS.map((preset) => (
        <div className="flex flex-col gap-y-[34px] mt-[34px]">
          <div className="rounded-[8px] shadow-default overflow-hidden border border-1 border-solid border-[#D0D5DD] py-3 px-6 text-[#667085] bg-[#F9FAFB]">
            <div className="flex items-center justify-between gap-x-[39px] font-normal text-base">
              <div className="flex flex-col flex-1 text-[#344054] gap-y-1">
                <p className="text-sm font-medium">{preset}</p>
              </div>
              <div className="flex items-center">
                <Checkbox
                  name={name}
                  checked={preset === selected}
                  onChange={(event) => { 
                    setValue(name, event.target.checked ? preset : "");
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <CustomCancellation
        custom={isCustom}
        setCustom={() => {
          setIsCustom((p) => !p);
          setValue(name, "");
        }}
      />
    </>
  );
};

export default CustomReturnAndCancellation;

const CustomCancellation = ({ custom, setCustom }) => {
  return (
    <div className="flex flex-col gap-y-[34px] my-[34px]">
      <div className="rounded-[8px] shadow-default overflow-hidden border border-1 border-solid border-[#D0D5DD] py-3 px-6 text-[#667085] bg-[#F9FAFB]">
        <div className="flex items-center justify-between gap-x-[39px] font-normal text-base">
          <div className="flex flex-col flex-1 text-[#344054] gap-y-1">
            <p className="text-sm font-medium">Use Custom</p>
          </div>
          <div className="flex items-center">
            <Checkbox checked={custom} onChange={setCustom} />
          </div>
        </div>
      </div>
    </div>
  );
};
