import { BIKES } from ".";

const getBikesBreadcrumb = (
  currentStep,
  { listingId, typeId },

  replacerFn = (breadcrumbs) => breadcrumbs
) => {
  if (!currentStep) {
    throw new Error("Current step is required to build breadcrumbs");
  }
  if (!listingId) {
    throw new Error("Listing id is required to build breadcrumbs");
  }

  const bikeType = BIKES.find((bike) => bike.id === typeId)?.title;

  let allBreadcrumbs = [
    {
      id: "select-listing",
      title: "Bikes",
      url: `/become-a-seller/${listingId}/select-listing`,
    },
    {
      id: "bikes",
      title: bikeType || "Select bike",
      url: `/become-a-seller/${listingId}/select-listing/${typeId}`,
    },
    {
      id: "equipment-details",
      title: "Details",
      url: `/become-a-seller/${listingId}/${typeId}/equipment-details`,
    },
    {
      id: "equipment-features",
      title: "Categories & Features",
      url: `/become-a-seller/${listingId}/${typeId}/equipment-features`,
    },
    {
      id: "rental-details",
      title: "Rental details",
      url: `/become-a-seller/${listingId}/${typeId}/rental-details`,
    },
    {
      id: "image-uploads",
      title: "Image Uploads",
      url: `/become-a-seller/${listingId}/${typeId}/uploads`,
    },
    {
      id: "price",
      title: "Price",
      url: `/become-a-seller/${listingId}/${typeId}/price`,
    },
    {
      id: "discount",
      title: "Discount",
      url: `/become-a-seller/${listingId}/${typeId}/discount`,
    },
    {
      id: "availability",
      title: "Availability",
      url: `/become-a-seller/${listingId}/${typeId}/availability`,
    },
    {
      id: "review",
      title: "Review",
      url: `/become-a-seller/${listingId}/${typeId}/review`,
    },
  ];
  allBreadcrumbs = replacerFn(allBreadcrumbs);
  return allBreadcrumbs
    .slice(0, currentStep)
    .map((breadcrumb, index, array) => {
      return {
        ...breadcrumb,
        url: array.length - 1 === index ? null : breadcrumb.url,
      };
    });
};

export default getBikesBreadcrumb;
