import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithPopup,
  signOut,
  signInWithCredential,
} from "firebase/auth";
import { addDoc, collection, getDocs, query, where } from "firebase/firestore";
import { auth, db } from "../lib/firebase";
import { parseJwt } from "../hooks/useGoogleTapSignin";
const userCollection = collection(db, "users");

export const getUserByEmail = async (email) => {
  const emailQuery = query(userCollection, where("email", "==", email));
  const querySnapshot = await getDocs(emailQuery);
  if (!querySnapshot.empty) {
    const userDoc = querySnapshot.docs[0];
    const userData = userDoc.data();
    return userData;
  } else {
    return null;
  }
};

export const saveUserData = async (userData) => {
  const user = await getUserByEmail(userData.email);
  if (!user) {
    await addDoc(userCollection, userData);
    return true;
  } else {
    return false;
  }
};

export const signUpWithCredentials = async (name, email, password) => {
  const response = await createUserWithEmailAndPassword(auth, email, password);
  // set the name of the user and providerId
  response.user.displayName = name;
  response.user.providerId = "email";
  const userData = transformFirebaseUser(response);
  await saveUserData(userData);
  return userData;
};

export const signInWithCredentials = (email, password) => {
  return signInWithEmailAndPassword(auth, email, password);
};

export const signInWithGoogle = async () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: "select_account",
  });
  const response = await signInWithPopup(auth, provider);
  const userData = transformFirebaseUser(response);
  await saveUserData(userData);
  return response;
};

export const logout = () => {
  return signOut(auth);
};

export const signInWithOneTapGoogle = async (credential) => {
  // store the user in db first and then login
  // decode the user first
  const parsedUser = parseJwt(credential);
  const userData = transformFirebaseUser({
    user: {
      uid: parsedUser.sub,
      displayName: parsedUser.name,
      email: parsedUser.email,
      phoneNumber: "",
      photoURL: parsedUser.picture,
      providerId: "google.com",
    },
  });
  await saveUserData(userData);
  // then connect the google tap login with firebase auth
  const cred = GoogleAuthProvider.credential(credential);
  const response = await signInWithCredential(auth, cred);
  return response;
};

// local functions
const transformFirebaseUser = (response) => {
  return {
    id: response.user.uid,
    displayName: response.user.displayName,
    email: response.user.email,
    dob: null,
    phoneNumber: response.user.phoneNumber,
    photoURL: response.user.photoURL,
    provider: response.user.providerId,
  };
};
