import React, { useEffect } from "react";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import { useParams } from "react-router";
import { useFormContext } from "react-hook-form";
import { createListingFormKeys } from "contexts/create-listing/validation";
import Checkbox from "components/FormControls/Checkbox";
import getBikesBreadcrumb from "../breadcrumbs";

const STEP_NUMBER = 8;

export const DISCOUNT_LIST = [
  {
    id: "new_listing_promotion",
    percentage: 20,
    title: "New listing promotion",
    description: "Offer 20% off your first 3 bookings",
  },
  {
    id: "weekly_discount",
    percentage: 10,
    title: "Weekly discount",
    description: "For booking of 7 days or more",
  },
  {
    id: "monthly discount",
    percentage: 25,
    title: "Monthly discount",
    description: "For booking of 28 days or more",
  },
];

const Discount = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  const params = useParams();

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const {
    watch,
    setValue,
    formState: { defaultValues },
  } = useFormContext();
  const discounts =
    watch(createListingFormKeys.discount) || defaultValues.discount;

  return (
    <ListingPage
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={false}
          nextUrl={`/become-a-seller/12345/${params.typeId}/availability`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto mt-10 md:mt-0 max-w-[600px]">
        <div>
          <h1 className="text-2xl font-bold text-[#344054] mb-[10px]">
            Add discounts
          </h1>
          <p className="text-sm font-medium text-[#344054]">
            Help your place stand out to get booked faster and earn your first
            reviews.
          </p>
        </div>

        {DISCOUNT_LIST.map((discount, i) => (
          <DiscountItem
            key={`discount__${i}`}
            {...discount}
            checked={discounts.includes(discount.id)}
            onChange={() => {
              // for single item
              // if (e.target.checked) {
              //   setValue(createListingFormKeys.discount, [
              //     // ...discounts,
              //     discount.id,
              //   ]);
              // } else {
              //   setValue(
              //     createListingFormKeys.discount,
              //     discounts.filter((d) => d.id !== discount.id)
              //   );
              // }
              // for multiple push in array
              const alreadychecked = discounts.includes(discount.id);
              console.log(alreadychecked, "already checkd");
              if (!alreadychecked) {
                setValue(createListingFormKeys.discount, [
                  ...discounts,
                  discount.id,
                ]);
              } else {
                setValue(
                  createListingFormKeys.discount,
                  discounts.filter((d) => d !== discount.id)
                );
              }
            }}
          />
        ))}
      </div>
    </ListingPage>
  );
};

export default Discount;

const DiscountItem = ({
  percentage,
  title,
  description,
  checked,
  onChange,
}) => {
  return (
    <div className="flex flex-col gap-y-[34px] mt-[34px]">
      <div className="rounded-[8px] shadow-default overflow-hidden border border-1 border-solid border-[#D0D5DD] py-3 px-6 text-[#667085] bg-[#F9FAFB]">
        <div className="flex items-center justify-between gap-x-[39px] font-normal text-base">
          <div className="flex items-center justify-center h-8 w-8">
            <span className="text-sm font-medium text-[#344054] py-[2px] px-2 border border-solid border-[#EAECF0] bg-white rounded-md">
              {percentage}%
            </span>
          </div>
          <div className="flex flex-col flex-1 text-[#344054] gap-y-1">
            <p className="text-sm font-medium">{title}</p>
            <p className="text-xs font-normal">{description}</p>
          </div>
          <div className="flex items-center">
            <Checkbox
              name={createListingFormKeys.discount}
              checked={checked}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
