import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider } from "react-helmet-async";

import App from "./App";
import store from "./store";
import "react-dates/lib/css/_datepicker.css";
import "./global.css";
import "./styles/app.sass";
import ToastProvider from "./components/Toast/ToastProvider";

const root = createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <Provider store={store}>
          <ToastProvider>
            <App />
          </ToastProvider>
        </Provider>
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);
