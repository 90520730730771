import React from "react";
import { ReactComponent as ThumbsUpIcon } from "icons/thumbs-up.svg";
import { ReactComponent as ArrowRightIcon } from "icons/arrow-right.svg";
import ListingPage from "components/Listings/ListingPage";
import { useCreateListingContext } from "contexts/create-listing";
import LinkButton from "components/LinkButton";
import IconButton from "components/IconButton";
import { clearPersistedListings } from "contexts/create-listing/utils";
import ReactConfetti from "components/ReactConfetti";

const STEP_NUMBER = 10;

const ListingSuccess = () => {
  const { setCurrentStep } = useCreateListingContext();
  React.useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);

  return (
    <ListingPage footer={null}>
      <ReactConfetti />
      <div className="md:max-w-2xl mx-auto">
        <div className="flex flex-col items-center gap-y-6">
          <IconButton
            variant="primary"
            className="bg-[#ECFDF3] hover:bg-[#ECFDF3] cursor-auto"
          >
            <ThumbsUpIcon />
          </IconButton>
          <h1 className="text-2xl md:text-3xl lg:text-5xl font-semibold flex-1">
            Thank you!
          </h1>
          <p>
            We have received your request, once an action is taken on your
            submission you will be notified
          </p>
          <LinkButton
            variant="primary"
            to="/vendor/listings"
            onClick={clearPersistedListings}
          >
            Go to dashboard
            <ArrowRightIcon className="!mr-0 !ml-2" />
          </LinkButton>
        </div>
      </div>
    </ListingPage>
  );
};

export default ListingSuccess;
