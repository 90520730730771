import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useFormContext } from "react-hook-form";
import Badge from "components/Badge";
import Icon from "components/Icon";
import { ReactComponent as CheckIcon } from "icons/check.svg";
import { ReactComponent as CloseIcon } from "icons/x.svg";
import { useCreateListingContext } from "contexts/create-listing";
import ListingPage from "components/Listings/ListingPage";
import ListingFooter from "components/Listings/ListingFooter";
import HintText from "components/HintText";
import { createListingFormKeys } from "contexts/create-listing/validation";
import Input from "components/FormControls/Input";
import getBikesBreadcrumb from "../breadcrumbs";
import IconButton from "components/IconButton";
import { ReactComponent as ValidIcon } from "icons/input-check.svg";

const STEP_NUMBER = 4;

const CATEGORIES = [
  "Hardtail",
  "Full-Suspension",
  "29-Inch Wheel (28ers)",
  "650B Wheel",
  "Fat Bikes",
  "Rigid",
  "Mountain Frames",
];
const FEATURES = ["Bike suspension", "Helmet", "Disk breaks", "Good battery"];

const CategoriesAndFeatures = () => {
  const { currentStep, setCurrentStep } = useCreateListingContext();
  useEffect(() => {
    setCurrentStep(STEP_NUMBER);
  }, []);
  const params = useParams();
  const [allCategories, setAllCategories] = useState(CATEGORIES);
  const [otherCategorySelection, setOtherCategorySelection] = useState(false);

  const [allFeatures, setAllFeatures] = useState(FEATURES);
  const [otherFeatureSelection, setOtherFeatureSelection] = useState(false);

  const { watch, setValue } = useFormContext();
  const selectedCategories =
    watch(createListingFormKeys.listingFeatures.categories) || [];

  const selectedFeatures =
    watch(createListingFormKeys.listingFeatures.features) || [];

  // sync selected categories if there are other than predefined
  useEffect(() => {
    if (selectedCategories.length > 0) {
      const otherCategories = selectedCategories.filter(
        (c) => !allCategories.includes(c)
      );
      setAllCategories([...otherCategories, ...allCategories]);
    }
  }, [selectedCategories]);

  useEffect(() => {
    if (selectedFeatures.length > 0) {
      const otherFeatures = selectedFeatures.filter(
        (f) => !allFeatures.includes(f)
      );
      setAllFeatures([...otherFeatures, ...allFeatures]);
    }
  }, [selectedFeatures]);

  const selectedCategoriesValid = selectedCategories.length > 0;
  const selectedFeaturesValid = selectedFeatures.length > 0;
  const isValid = selectedCategoriesValid && selectedFeaturesValid;

  const breadcrumbs = React.useMemo(() => {
    return getBikesBreadcrumb(currentStep, {
      listingId: params.listingId,
      typeId: params.typeId,
    });
  }, [currentStep, params]);

  const onAddCategory = (newCategory) => {
    if (!allCategories.includes(newCategory)) {
      setAllCategories((p) => [newCategory, ...p]);
      setValue(createListingFormKeys.listingFeatures.categories, [
        newCategory,
        ...selectedCategories,
      ]);
    }

    setOtherCategorySelection(false);
  };
  const onAddFeature = (newFeature) => {
    if (!allFeatures.includes(newFeature)) {
      setAllFeatures((p) => [newFeature, ...p]);
      setValue(createListingFormKeys.listingFeatures.features, [
        newFeature,
        ...selectedFeatures,
      ]);
    }
    setOtherFeatureSelection(false);
  };

  return (
    <ListingPage
      breadcrumbs={breadcrumbs}
      footer={
        <ListingFooter
          disableNext={!isValid}
          nextUrl={`/become-a-seller/12345/${params.typeId}/rental-details`}
        />
      }
    >
      <div className="md:max-w-screen-md w-full mx-auto">
        <div className="flex flex-col items-start">
          {/* Categories */}
          <div className="w-full">
            <div className="flex items-center justify-between mt-10 md:mt-0 mb-5">
              <h1 className="text-2xl md:text-3xl font-semibold flex-1 asterisk">
                Categories
              </h1>
              {selectedCategoriesValid && (
                <div>
                  <ValidIcon />
                </div>
              )}
            </div>

            <div className="w-full mb-[10px]">
              <div className="flex flex-wrap gap-[10px] my-[10px]">
                {allCategories.map((category, i) => (
                  <Badge
                    key={`category__${i}`}
                    selected={selectedCategories.includes(category)}
                    onClick={() => {
                      setValue(
                        createListingFormKeys.listingFeatures.categories,
                        selectedCategories.includes(category)
                          ? selectedCategories.filter((c) => c !== category)
                          : [category, ...selectedCategories]
                      );
                    }}
                  >
                    {category}
                  </Badge>
                ))}

                <Badge
                  rightIcon={
                    <Icon
                      className="ml-[2px]"
                      name="plus"
                      size="20"
                      fill="#374151"
                    />
                  }
                  onClick={() => setOtherCategorySelection((p) => !p)}
                >
                  Other
                </Badge>
              </div>

              <NewSelection
                type="category"
                show={otherCategorySelection}
                setShow={setOtherCategorySelection}
                onAdd={onAddCategory}
              />
            </div>
            <div className="my-6">
              <HintText>
                Select 1 or more categories{" "}
                <a className="font-semibold">Learn More</a>
              </HintText>
            </div>
          </div>

          {/* Features */}
          <div className="w-full">
            <div className="flex items-center justify-between mb-5">
              <h1 className="text-2xl md:text-3xl font-semibold mb-5 asterisk">
                Features
              </h1>
              {selectedFeaturesValid && (
                <div>
                  <ValidIcon />
                </div>
              )}
            </div>
            <div className="w-full mb-[10px]">
              <div className="flex flex-wrap gap-[10px] my-[10px]">
                {allFeatures.map((feature, i) => (
                  <Badge
                    key={`feature__${i}`}
                    selected={selectedFeatures.includes(feature)}
                    onClick={() => {
                      setValue(
                        createListingFormKeys.listingFeatures.features,
                        selectedFeatures.includes(feature)
                          ? selectedFeatures.filter((f) => f !== feature)
                          : [feature, ...selectedFeatures]
                      );
                    }}
                  >
                    {feature}
                  </Badge>
                ))}

                <Badge
                  rightIcon={
                    <Icon
                      className="ml-[2px]"
                      name="plus"
                      size="20"
                      fill="#374151"
                    />
                  }
                  onClick={() => setOtherFeatureSelection((p) => !p)}
                >
                  Other
                </Badge>
              </div>

              <NewSelection
                type="feature"
                show={otherFeatureSelection}
                setShow={setOtherFeatureSelection}
                onAdd={onAddFeature}
              />
            </div>
            <div className="my-6">
              <HintText>
                Select 1 or more features{" "}
                <a className="font-semibold">Learn More</a>
              </HintText>
            </div>
          </div>
        </div>
      </div>
    </ListingPage>
  );
};

export default CategoriesAndFeatures;
const NewSelection = memo(({ show, setShow, type, onAdd }) => {
  // reset form when component unmounts
  useEffect(() => {
    return () => {
      setError("");
      setTitle("");
    };
  }, [show]);
  const [title, setTitle] = useState("");
  const [error, setError] = useState("");

  const onSubmit = () => {
    if (!title) {
      setError(new Error("Required"));
      return;
    }
    onAdd(title);
  };

  if (!show) {
    return null;
  }

  return (
    <form
      noValidate
      className="flex gap-x-8"
      onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation();
        onSubmit();
      }}
    >
      <div className="flex-1">
        <Input
          autoFocus
          type="text"
          placeholder={`Enter new ${type}`}
          value={title}
          onChange={(event) => {
            setError("");
            setTitle(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              onSubmit();
            }
          }}
          error={error}
        />
        {title && (
          <HintText className="text-xs">
            Enter a new {type} <strong>{title}</strong>, hit enter to save
          </HintText>
        )}
      </div>
      <div className="flex gap-x-4">
        <IconButton variant="primary" tooltip="Add" onClick={onSubmit}>
          <CheckIcon className="cursor-pointer" />
        </IconButton>
        <IconButton tooltip="Cancel" onClick={() => setShow(false)}>
          <CloseIcon className="cursor-pointer" />
        </IconButton>
      </div>
    </form>
  );
});
